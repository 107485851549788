import React, {
  useState,
  useEffect,
  forwardRef,
  startTransition,
  useContext,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Drawer } from "@mui/material";
import RepoIcon from "../assets/images/icons/repo_icon";
import AcedoIcon from "../assets/images/icons/acedo_icon";
import Document from "../assets/images/icons/doc";
import Studio from "../assets/images/icons/studio";
import Bird from "../assets/images/icons/bird";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import useMediaQuery from "@mui/material/useMediaQuery";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import {
  IconLayoutDashboard,
  IconDashboard,
  IconChartDots3,
  IconBrandMetabrainz,
} from "@tabler/icons-react";
// import IconChartDots3 from "../assets/images/icons/Acedo.svg";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PerfectScrollbar from "react-perfect-scrollbar";
import MuiChip from "@mui/material/Chip";
import { alpha } from "@mui/material/styles";
import { IconBrandChrome, IconHelp } from "@tabler/icons-react";
import Logo from "./Logo";

import { BrowserView, MobileView } from "react-device-detect";
import Stack from "@mui/material/Stack";
import "../views/ui/main.css";
import "@fortawesome/fontawesome-free/css/all.css";
import bg1 from "../assets/images/bg/AceETL.png";
import bg2 from "../assets/images/bg/AceDo.png";
import bg5 from "../assets/images/bg/devtools.png";
import bg6 from "../assets/images/bg/swagger.png";
import bg7 from "../assets/images/bg/studio.png";
import image from "../assets/images/bg/image__12_-removebg-preview.png";
import logos from "./../assets/images/logos/logo.png";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { DataContext } from "../DataContext";
import Tooltip from "@mui/material/Tooltip";
import CopilotIcon from "../assets/images/icons/copilot_config";
const apiUrl = process.env.REACT_APP_API_URL;
const copilotUrl = process.env.REACT_APP_COPILOT_API_URL;
const path = process.env.REACT_APP_ACE_AI;
const swagger = process.env.REACT_APP_SWAGGER;
const dse = process.env.REACT_APP_DSE_STUDIO;

const Chip = ({ chipcolor, disabled, sx = {}, variant, ...others }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    width: isMobile ? "90%" : 400,
  };

  let defaultSX = {
    color: "primary.main",
    bgcolor: "primary.light",
    ":hover": {
      color: "primary.light",
      bgcolor: "primary.dark",
    },
  };

  let outlineSX = {
    color: "primary.main",
    bgcolor: "transparent",
    border: "1px solid",
    borderColor: "primary.main",
    ":hover": {
      color: "primary.light",
      bgcolor: "primary.dark",
    },
  };

  switch (chipcolor) {
    case "secondary":
      variant === "outlined"
        ? (outlineSX = {
            color: "secondary.main",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "secondary.main",
            ":hover": {
              color: "secondary.main",
              bgcolor: "secondary.light",
            },
          })
        : (defaultSX = {
            color: "secondary.main",
            bgcolor: "secondary.light",
            ":hover": {
              color: "secondary.light",
              bgcolor: "secondary.main",
            },
          });
      break;
    case "success":
      variant === "outlined"
        ? (outlineSX = {
            color: "success.dark",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "success.dark",
            ":hover": {
              color: "success.dark",
              bgcolor: alpha(theme.palette.success.light, 0.6),
            },
          })
        : (defaultSX = {
            color: "success.dark",
            bgcolor: alpha(theme.palette.success.light, 0.6),
            ":hover": {
              color: "success.light",
              bgcolor: "success.dark",
            },
          });
      break;
    case "error":
      variant === "outlined"
        ? (outlineSX = {
            color: "error.main",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "error.main",
            ":hover": {
              color: "error.dark",
              bgcolor: "error.light",
            },
          })
        : (defaultSX = {
            color: "error.dark",
            bgcolor: alpha(theme.palette.error.light, 0.6),
            ":hover": {
              color: "error.light",
              bgcolor: "error.dark",
            },
          });
      break;
    case "orange":
      variant === "outlined"
        ? (outlineSX = {
            color: "orange.dark",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "orange.main",
            ":hover": {
              color: "orange.dark",
              bgcolor: "orange.light",
            },
          })
        : (defaultSX = {
            color: "orange.dark",
            bgcolor: "orange.light",
            ":hover": {
              color: "orange.light",
              bgcolor: "orange.dark",
            },
          });
      break;
    case "warning":
      variant === "outlined"
        ? (outlineSX = {
            color: "warning.dark",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "warning.dark",
            ":hover": {
              color: "warning.dark",
              bgcolor: "warning.light",
            },
          })
        : (defaultSX = {
            color: "warning.dark",
            bgcolor: "warning.light",
            ":hover": {
              color: "warning.light",
              bgcolor: "warning.dark",
            },
          });
      break;
    default:
  }

  if (disabled) {
    variant === "outlined"
      ? (outlineSX = {
          color: "grey.500",
          bgcolor: "transparent",
          border: "1px solid",
          borderColor: "grey.500",
          ":hover": {
            color: "grey.500",
            bgcolor: "transparent",
          },
        })
      : (defaultSX = {
          color: "grey.500",
          bgcolor: "grey.50",
          ":hover": {
            color: "grey.500",
            bgcolor: "grey.50",
          },
        });
  }

  let SX = defaultSX;
  if (variant === "outlined") {
    SX = outlineSX;
  }
  SX = { ...SX, ...sx };
  return <MuiChip {...others} sx={SX} />;
};

Chip.propTypes = {
  sx: PropTypes.object,
  chipcolor: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

// ==============================|| SIDEBAR COMPONENT ||============================== //

const SideBar = ({ drawerOpen, drawerToggle, window }) => {
  const VITE_APP_VERSION = "v3.5.0";
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userRole = JSON.parse(sessionStorage.getItem("user"));
  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <Logo />
        </Box>
      </Box>

      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <MenuList />
          {userRole?.role === "admin" && <MenuCard />}
          <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            {/* <Chip
              label={VITE_APP_VERSION}
              disabled
              chipcolor="secondary"
              size="small"
              sx={{ cursor: "pointer" }}
            /> */}
          </Stack>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          {userRole?.role === "admin" && <MenuCard />}
          <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            {/* <Chip
              label={VITE_APP_VERSION}
              disabled
              chipcolor="secondary"
              size="small"
              sx={{ cursor: "pointer" }}
            /> */}
          </Stack>
        </Box>
      </MobileView>
    </>
  );

  const drawerWidth = 240;
  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const MenuList = () => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { selectedChatId, setSelectedChatId } = useContext(DataContext);
  const { containerAdd, setContainerAdd } = useContext(DataContext);
  const { container, setContainer } = useContext(DataContext);
  const { newChat } = useContext(DataContext);
  const userRole = JSON.parse(sessionStorage.getItem("user"));
  const [exploreOpen, setExploreOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editContainer, setEditContainer] = useState(false);
  const [editName, setEditName] = useState("");
  const [editingChatId, setEditingChatId] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDevToolsToggle = () => {
    setIsDevToolsOpen(!isDevToolsOpen);
  };

  const toggleExplore = () => setExploreOpen(!exploreOpen);
  const userData = JSON.parse(sessionStorage.getItem("user"));

  const adminNavigation = {
    items: [
      {
        id: "dashboard",
        // title: "AceETL",
        type: "group",
        children: [
          {
            id: "dashboard-home",
            title: "Dashboard",
            type: "item",
            url: "/", // Corresponds to the redirect route
            icon: IconLayoutDashboard,
          },
          {
            id: "etl-source",
            title: "Sources",
            type: "item",
            url: "/ETL", // Corresponds to "/ETL" path in ThemeRoutes
            icon: IconDashboard,
          },
        ],
      },
      {
        id: "sample-docs-roadmap",
        type: "group",
        children: [
          {
            id: "acedo",
            title: "AceDO",
            type: "item",
            url: "/ADO",
            icon: AcedoIcon,
          },
          {
            id: "repo-ai",
            title: "AceAI",
            type: "item",
            url: "/RepoAI",
            icon: RepoIcon,
          },
          {
            id: "config",
            title: "Copilot Config",
            type: "item",
            url: "/Copilot-cofig",
            icon: CopilotIcon,
          },
        ],
      },
    ],
  };

  const devNavigation = {
    items: [
      {
        id: "copilot-group",
        title: "CoPilot",
        hide: "true",
        type: "group",
        children: [
          {
            id: "copilot",
            title: "CoPilot",
            type: "item",
            url: "/co-pilot",
            icon: Bird,
            onClick: () => {
              setSelectedChatId(null);
              handleFunction();
            },
          },
        ],
      },
      {
        id: "dev-tools-group",
        title: "Dev Tools",
        type: "group",
        hide: true,
        icon: AcedoIcon,
        onClick: handleDevToolsToggle,
        children: isDevToolsOpen
          ? [
              {
                id: "aceai",
                title: "AceAI",
                type: "item",
                url: `${path}/home`,
                icon: RepoIcon,
                isExternal: true,
              },
              {
                id: "aceapi-docs",
                title: "AceAPI Docs",
                type: "item",
                url: `${swagger}/ace-api/`,
                icon: Document,
                isExternal: true,
              },
              {
                id: "aceai-studio",
                title: "AceAI Studio",
                type: "item",
                url: `${dse}`, // External link
                icon: Studio,
                isExternal: true,
              },
              // Uncomment if you want to add "AceAI Studio-2"
              // {
              //   id: "aceai-studio-2",
              //   title: "AceAI Studio-2",
              //   type: "item",
              //   url: `${dse2}`, // External link
              //   icon: bg7,
              //   isExternal: true,
              // },
            ]
          : [],
      },
    ],
  };

  const userNavigation = {
    items: [
      {
        id: "copilot-group",
        title: "CoPilot",
        type: "group",
        hide: "true",
        children: [
          {
            id: "copilot",
            title: "CoPilot",
            type: "item",
            url: "/co-pilot",
            icon: AcedoIcon,
            onClick: () => {
              setSelectedChatId(null);
              handleFunction();
            },
          },
        ],
      },
    ],
  };

  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    width: "30%",
    "@media (max-width: 768px)": {
      width: "90%",
    },
    "@media (max-width: 480px)": {
      width: "95%",
    },
  };

  let menuItem = {};
  if (userRole?.role === "admin") {
    menuItem = adminNavigation;
  } else if (userRole?.role === "dev") {
    menuItem = devNavigation;
  } else {
    menuItem = userNavigation;
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (userRole?.role !== "admin") {
      setSelectedItemId("copilot");
    } else {
      let path = window.location.pathname;
      let endName = path.substring(path.lastIndexOf("/") + 1);
      if (endName === "ETL") {
        setSelectedItemId("etl-source");
      } else {
        setSelectedItemId("dashboard-home");
      }
    }
  }, []);

  const toggleDrop = (e, chatId) => {
    const { clientX, clientY } = e;
    if (selectedChatId === chatId) {
      setDropdownVisible(true);
      setDropdownOpenId(chatId);
    } else {
      setDropdownVisible(true);
      setDropdownOpenId(chatId);
    }
    setDropdownPosition({
      top: clientY,
      left: clientX,
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
      setDropdownOpenId(null);
    }
  };

  const handleChatClick = (chatId) => {
    if (selectedChatId != chatId) {
      sessionStorage.setItem("containerId", chatId);
      setSelectedChatId(chatId);
      console.log(chatId);
      setContainer(0);
      toggleDrop(chatId);
    }
  };
  const DropdownMenu = ({ position, onEdit, onDelete }) => {
    return (
      <div
        role="menu"
        aria-orientation="vertical"
        className=" mt-1 rounded popover hover:bg-[#fff]"
        style={{
          zIndex: "100000",
          position: "fixed",
          left: position.left + 10,
          top: position.top,
        }}
        ref={dropdownRef}
      >
        <MenuItem icon="fa-solid fa-pen" title="Edit" onClick={onEdit} />
        <MenuItem icon="fa-solid fa-trash" title="Delete" onClick={onDelete} />
      </div>
    );
  };

  const MenuItem = ({ icon, title, onClick }) => (
    <div
      role="menuitem"
      className="flex text-black d-flex items-center  p-2.5 text-sm cursor-pointer focus-visible:outline-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group hover:bg-[#f5f5f5] focus-visible:bg-[#f5f5f5] dark:hover:bg-token-main-surface-secondary dark:focus-visible:bg-token-main-surface-secondary rounded-md my-0 px-3 radix-state-open:bg-[#f5f5f5] dark:radix-state-open:bg-token-main-surface-secondary gap-2.5 py-1"
      tabIndex="-1"
      onClick={onClick}
    >
      <div className="flex items-center justify-center text-token-text-secondary h-5 w-5 me-3">
        <i className={`fas ${icon}`}></i>
      </div>
      {title}
    </div>
  );

  const getChatHistory = async () => {
    try {
      const response = await axios.get(
        `${copilotUrl}/copilot/get-chat-containers/`,
        { params: { user_id: userData?.user_id } }
      );
      let data = response?.data;
      setChatHistory(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteChat = (ele) => {
    setDropdownVisible(false);
    setDeleteModal(true);
  };

  const handleFunction = () => {
    setContainerAdd((prevValue) => prevValue + 1);
  };

  const handleModalClosed = () => {
    setDropdownVisible(false);
    setDeleteModal(false);
  };

  const handleEditClick = (chatId, chatName) => {
    setEditingChatId(chatId);
    setEditName(chatName);
  };

  const handleSaveEdit = async () => {
    setDropdownVisible(false);
    try {
      let data = {
        chat_container_id: editingChatId,
        user_id: userData?.user_id,
        chat_container_name: editName,
      };

      await axios.post(`${copilotUrl}/copilot/save-chat-container/`, data);

      setEditingChatId(null);
      getChatHistory();
    } catch (error) {
      console.error("Error communicating with the API:", error.message);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      let data = {
        chat_container_id: selectedChatId,
        user_id: userData?.user_id,
      };
      console.log(data);
      const response = await axios.delete(
        `${copilotUrl}/copilot/delete-chat-container/`,
        {
          params: {
            chat_container_id: selectedChatId,
            user_id: userData?.user_id,
          },
        }
      );
      setDeleteModal(false);
      getChatHistory();
      setContainerAdd((prevValue) => prevValue + 1);
      setSelectedChatId(null);
    } catch (error) {
      setDeleteModal(false);
      console.error("Error communicating with the API:", error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveEdit();
    }
  };

  const callContainer = async () => {
    if (newChat) {
      try {
        const response = await axios.get(
          `${copilotUrl}/copilot/get-chat-containers/`,
          { params: { user_id: userData?.user_id } }
        );
        let data = response?.data;
        console.log(data);
        setSelectedChatId(data?.todayChatHistory?.[0]?.chat_container_id);
        setChatHistory(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (userData?.role == "dev" || userData?.role == "endUser") {
      getChatHistory();
    }
    setSelectedChatId(null);
  }, []);

  useEffect(() => {
    callContainer();
  }, [newChat]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const NavGroup = ({
    item,
    selectedItemId,
    setSelectedItemId,
    isDevToolsOpen,
    handleDevToolsToggle,
  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { pathname } = useLocation();
    const [isGroupOpen, setIsGroupOpen] = useState(false);

    const handleItemClick = (id) => {
      setSelectedItemId(id);
      handleFunction();
      setSelectedChatId(null);
      sessionStorage.setItem("containerId", null);
    };

    const handleGroupClick = () => {
      setIsGroupOpen(!isGroupOpen);
    };

    // Render icon or image based on the type of value provided
    const renderIcon = (Icon, isSelected) => {
      if (!Icon) return null;

      return (
        <Icon
          sx={{
            stroke: isSelected ? 1.5 : 1,
            fontSize: isSelected ? "1.3rem" : "1rem",
          }}
        />
      );
    };

    if (item.id === "dev-tools-group") {
      const iconPath =
        process.env.PUBLIC_URL + "/assets/images/icons/acedo_icon";

      return (
        <>
          <ListItemButton
            onClick={handleDevToolsToggle}
            sx={{
              borderRadius: `8px`,
              mb: 0.5,
              alignItems: "flex-start",
              backgroundColor: isGroupOpen ? "#E5F3FFFF" : "transparent",
              py: 1.25,
              pl: `${1 * 24}px`,
              "&:hover": {
                backgroundColor: "#E5F3FFFF",
                color: "#1565c0",
              },
            }}
          >
            <ListItemIcon
              sx={{ my: "auto", minWidth: 36 }}
              style={{
                color: selectedItemId === item.id ? "#1E88E5FF" : "",
                fill:
                  selectedItemId === item.id
                    ? "#1E88E5FF"
                    : "rgba(0, 0, 0, 0.54)",
              }}
            >
              {renderIcon(item.icon, selectedItemId === item.id)}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  {item.title}
                </Typography>
              }
            />
            {isDevToolsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={isDevToolsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children && item.children.length > 0 ? (
                item.children.map((menu) => {
                  const Icon = menu.icon;
                  const itemIcon = menu?.icon ? (
                    <Icon stroke={1.5} size="1.3rem" />
                  ) : (
                    <FiberManualRecordIcon
                      sx={{
                        width: selectedItemId === menu.id ? 8 : 6,
                        height: selectedItemId === menu.id ? 8 : 6,
                      }}
                      fontSize={1}
                    />
                  );

                  const itemTarget = menu.isExternal ? "_blank" : "_self";

                  const listItemProps = menu.isExternal
                    ? { component: "a", href: menu.url, target: itemTarget }
                    : {
                        component: forwardRef((props, ref) => (
                          <Link
                            ref={ref}
                            {...props}
                            to={menu.url}
                            target={itemTarget}
                          />
                        )),
                      };

                  return (
                    <ListItemButton
                      {...listItemProps}
                      key={menu.id}
                      disabled={menu.disabled}
                      sx={{
                        borderRadius: `8px`,
                        mb: 0.5,
                        alignItems: "flex-start",
                        backgroundColor:
                          selectedItemId === menu.id
                            ? "#E5F3FFFF"
                            : "transparent",
                        py: 1.25,
                        pl: `${2 * 24}px`,
                        "&:hover": {
                          backgroundColor: "#E5F3FFFF",
                          color: "#1565c0",
                          ".MuiListItemText-primary": {
                            color: "#1565c0",
                          },
                          ".MuiListItemIcon-root": {
                            color: "#1565c0",
                            fill: "#1565c0 !important",
                          },
                        },
                      }}
                      selected={selectedItemId === menu.id}
                      onClick={() => handleItemClick(menu.id)}
                    >
                      <ListItemIcon
                        sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}
                        style={{
                          color: selectedItemId === menu.id ? "#1E88E5FF" : "",
                          fill:
                            selectedItemId === menu.id
                              ? "#1E88E5FF"
                              : "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {itemIcon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant={
                              selectedItemId === menu.id ? "h5" : "body1"
                            }
                            color="inherit"
                            sx={{
                              fontFamily: "Roboto, sans-serif",
                              fontWeight: "500",
                              lineHeight: "1.66",
                            }}
                            style={{
                              color:
                                selectedItemId === menu.id ? "#1565c0" : "",
                            }}
                          >
                            {menu.title}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  );
                })
              ) : (
                <Typography variant="body2" sx={{ pl: 3 }}>
                  No children available
                </Typography>
              )}
            </List>
          </Collapse>
          <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
      );
    }

    if (item.hide === "true") {
      return (
        <>
          {item.children?.map((menu) => {
            const Icon = menu.icon;
            const itemIcon = menu?.icon ? (
              <Icon stroke={1.5} size="1.3rem" />
            ) : (
              <FiberManualRecordIcon
                sx={{
                  width: selectedItemId === menu.id ? 8 : 6,
                  height: selectedItemId === menu.id ? 8 : 6,
                }}
                fontSize={1}
              />
            );

            const itemTarget = menu.target ? "_blank" : "_self";

            const listItemProps = menu.external
              ? { component: "a", href: menu.url, target: itemTarget }
              : {
                  component: forwardRef((props, ref) => (
                    <Link
                      ref={ref}
                      {...props}
                      to={menu.url}
                      target={itemTarget}
                    />
                  )),
                };

            return (
              <ListItemButton
                {...listItemProps}
                key={menu.id}
                disabled={menu.disabled}
                sx={{
                  borderRadius: `8px`,
                  mb: 0.5,
                  alignItems: "flex-start",
                  backgroundColor:
                    selectedItemId === menu.id ? "#E5F3FFFF" : "transparent",
                  py: 1.25,
                  pl: `${1 * 24}px`,
                  "&:hover": {
                    backgroundColor: "#E5F3FFFF",
                    color: "#1565c0",
                    ".MuiListItemText-primary": {
                      color: "#1565c0",
                    },
                    ".MuiListItemIcon-root": {
                      color: "#1565c0",
                      fill: "#1565c0 !important",
                    },
                  },
                }}
                selected={selectedItemId === menu.id}
                onClick={() => handleItemClick(menu.id)}
              >
                <ListItemIcon
                  sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}
                  style={{
                    color: selectedItemId === menu.id ? "#1E88E5FF" : "",
                    fill:
                      selectedItemId === menu.id
                        ? "#1E88E5FF"
                        : "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {itemIcon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant={selectedItemId === menu.id ? "h5" : "body1"}
                      color="inherit"
                      sx={{
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: "500",
                        lineHeight: "1.66",
                      }}
                      style={{
                        color: selectedItemId === menu.id ? "#1565c0" : "",
                      }}
                    >
                      {menu.title}
                    </Typography>
                  }
                />
              </ListItemButton>
            );
          })}
        </>
      );
    }

    return (
      <>
        {item.children?.map((menu) => {
          const Icon = menu.icon;
          const itemIcon = menu?.icon ? (
            <Icon stroke={1.5} size="1.3rem" />
          ) : (
            <FiberManualRecordIcon
              sx={{
                width: selectedItemId === menu.id ? 8 : 6,
                height: selectedItemId === menu.id ? 8 : 6,
              }}
              fontSize={1}
            />
          );

          const itemTarget = menu.target ? "_blank" : "_self";

          const listItemProps = menu.external
            ? { component: "a", href: menu.url, target: itemTarget }
            : {
                component: forwardRef((props, ref) => (
                  <Link
                    ref={ref}
                    {...props}
                    to={menu.url}
                    target={itemTarget}
                  />
                )),
              };

          return (
            <ListItemButton
              {...listItemProps}
              key={menu.id}
              disabled={menu.disabled}
              sx={{
                borderRadius: `8px`,
                mb: 0.5,
                alignItems: "flex-start",
                backgroundColor:
                  selectedItemId === menu.id ? "#E5F3FFFF" : "transparent",
                py: 1.25,
                pl: `${1 * 24}px`,
                "&:hover": {
                  backgroundColor: "#E5F3FFFF",
                  color: "#1565c0",
                  ".MuiListItemText-primary": {
                    color: "#1565c0",
                  },
                  ".MuiListItemIcon-root": {
                    color: "#1565c0",
                    fill: "#1565c0 !important",
                  },
                },
              }}
              selected={selectedItemId === menu.id}
              onClick={() => handleItemClick(menu.id)}
            >
              <ListItemIcon
                sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}
                style={{
                  color: selectedItemId === menu.id ? "#1E88E5FF" : "",
                  fill:
                    selectedItemId === menu.id
                      ? "#1E88E5FF"
                      : "rgba(0, 0, 0, 0.54)",
                }}
              >
                {itemIcon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant={selectedItemId === menu.id ? "h5" : "body1"}
                    color="inherit"
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: "500",
                      lineHeight: "1.66",
                    }}
                    style={{
                      color: selectedItemId === menu.id ? "#1565c0" : "",
                    }}
                  >
                    {menu.title}
                  </Typography>
                }
              />
            </ListItemButton>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          overflowY: "auto",
          maxHeight:
            userRole?.role === "dev"
              ? "calc(100vh - 125px)"
              : "calc(100vh - 125px)",
        }}
      >
        {menuItem.items.map((item) => (
          <NavGroup
            key={item.id}
            item={item}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            isDevToolsOpen={isDevToolsOpen}
            handleDevToolsToggle={handleDevToolsToggle}
          />
        ))}
        {(userRole?.role === "dev" || userRole?.role === "endUser") && (
          <>
            <div>
              <div>
                <div className="z-20">
                  <span className="flex h-9 items-center">
                    <h6 className="fw-bold pb-2 pt-3 px-2">Today</h6>
                  </span>
                </div>
                <div
                  className="relative first:mt-0 last:mb-5 empty:mt-0 empty:hidden"
                  style={{
                    opacity: 1,
                    width: "190px",
                  }}
                >
                  {chatHistory?.todayChatHistory?.map((chat) => (
                    <div
                      key={chat.id}
                      className={`chatContainer ${
                        selectedChatId === chat.chat_container_id
                          ? "selected"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleChatClick(chat.chat_container_id);
                        console.log(
                          "test",
                          selectedChatId,
                          chat.chat_container_id
                        );
                      }}
                    >
                      <div className="px-2 p-1 p-2 pb-1 rounded group relative rounded-lg active:opacity-90 d-flex">
                        {editingChatId === chat.chat_container_id ? (
                          <div className="d-flex">
                            <div style={{ width: "135px" }}>
                              <input
                                type="text"
                                className="form-control w-100 bg-white editInput"
                                name="chat_container_name"
                                value={editName}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setEditName(e.target.value)}
                              />
                            </div>
                            <i
                              className="ms-2 fa fa-times fa-sm fs-6 mt-2"
                              onClick={() => setEditingChatId(null)}
                            ></i>
                            <i
                              className="ms-2 fa-solid fa-floppy-disk fs-6 fa-sm mt-3"
                              onClick={handleSaveEdit}
                            ></i>
                          </div>
                        ) : (
                          <>
                            <div
                              className="overflow-hidden mb-2 ps-2 truncatedText"
                              dir="auto"
                            >
                              <Tooltip
                                title={chat?.chat_container_name}
                                placement="top"
                              >
                                {truncateText(chat.chat_container_name, 18)}
                              </Tooltip>
                            </div>
                            <i
                              className="ms-auto fa-solid fa-ellipsis-h cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDrop(e, chat?.chat_container_id);
                              }}
                            ></i>
                            {dropdownVisible &&
                              dropdownOpenId === chat?.chat_container_id && (
                                <DropdownMenu
                                  onEdit={() =>
                                    handleEditClick(
                                      chat.chat_container_id,
                                      chat.chat_container_name
                                    )
                                  }
                                  onDelete={() =>
                                    handleDeleteChat(chat.chat_container_id)
                                  }
                                  position={dropdownPosition}
                                  ref={dropdownRef}
                                />
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="z-20">
                  <span className="flex h-9 items-center">
                    <h6 className="fw-bold pb-2 pt-3 px-2">Previous Days</h6>
                  </span>
                </div>
                <div
                  className="relative mt-1 first:mt-0 last:mb-5 empty:mt-0 empty:hidden"
                  style={{
                    opacity: 1,
                    width: "190px",
                  }}
                >
                  {chatHistory?.previousDaysChatHistory?.map((chat) => (
                    <div
                      key={chat.id}
                      className={`chatContainer ${
                        selectedChatId === chat.chat_container_id
                          ? "selected"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleChatClick(chat.chat_container_id);
                        console.log(
                          "test",
                          selectedChatId,
                          chat.chat_container_id
                        );
                      }}
                    >
                      <div className="px-2 p-2 pb-1 rounded group relative rounded-lg active:opacity-90 d-flex">
                        {editingChatId === chat.chat_container_id ? (
                          <div className="d-flex">
                            <div style={{ width: "135px" }}>
                              <input
                                type="text"
                                className="form-control w-100 bg-white editInput"
                                name="chat_container_name"
                                value={editName}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setEditName(e.target.value)}
                              />
                            </div>
                            <i
                              className="ms-2 fa fa-times fa-sm fs-6 mt-2"
                              onClick={() => setEditingChatId(null)}
                            ></i>
                            <i
                              className="ms-2 fa-solid fa-floppy-disk fa-sm fs-6 mt-3 me-1"
                              onClick={handleSaveEdit}
                            ></i>
                          </div>
                        ) : (
                          <>
                            <div
                              className="overflow-hidden mb-2 ps-2 truncatedText"
                              dir="auto"
                            >
                              <Tooltip
                                title={chat?.chat_container_name}
                                placement="top"
                              >
                                {truncateText(chat.chat_container_name, 18)}
                              </Tooltip>
                            </div>
                            <i
                              className="ms-auto p-2 pt-1 fa-solid fa-ellipsis-h cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDrop(e, chat?.chat_container_id);
                              }}
                            ></i>
                            {dropdownVisible &&
                              dropdownOpenId === chat?.chat_container_id && (
                                <DropdownMenu
                                  onEdit={() =>
                                    handleEditClick(
                                      chat.chat_container_id,
                                      chat.chat_container_name
                                    )
                                  }
                                  onDelete={() =>
                                    handleDeleteChat(chat.chat_container_id)
                                  }
                                  position={dropdownPosition}
                                  ref={dropdownRef}
                                />
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: isMobile ? "90%" : "30%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              color: "black",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
          >
            <Typography variant={isMobile ? "h4" : "h4"} gutterBottom>
              Delete Chat container
            </Typography>
          </div>
          <div className=" d-flex justify-content-center align-items-center p-3">
            <p>Are you sure you want to delete?</p>
          </div>
          <div className=" d-flex justify-content-center align-items-center pb-3">
            <button
              className=" ms-5 me-4 form-control "
              onClick={() => handleModalClosed()}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e0e0e0")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#f0f0f0")}
            >
              No
            </button>
            <button
              className="form-control me-5 "
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                border: "1px solid #007bff",
                transition: "background-color 0.3s",
              }}
              onClick={() => handleConfirmDelete()}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#007bff")}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

// ==============================|| SIDEBAR MENU CARD ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
  return (
    <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" sx={{ color: "#1565c0" }}>
              Job Status
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="inherit"
              sx={{ fontSize: "0.75rem", color: "#697586" }}
            >{`${Math.round(value)}%`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LinearProgress
          aria-label="progress of theme"
          variant="determinate"
          value={value}
          {...others}
          sx={{
            height: 10,
            borderRadius: 30,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              bgcolor: "background.paper",
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              bgcolor: "primary.dark",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

// ==============================|| SIDEBAR - MENU CARD ||============================== //

const MenuCard = () => {
  const theme = useTheme();
  const [jobStatus, setJobStatus] = useState([]);

  const getJobStatus = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/prefect/api/flow_job_history/`
      );
      let data = response?.data;
      console.log(data);
      setJobStatus(data);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    getJobStatus();
    const interval = setInterval(() => {
      getJobStatus();
    }, 1000000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      {/* <Card
        sx={{
          bgcolor: "#EEF2F6FF",
          mb: 2.75,
          overflow: "hidden",
          position: "absolute",
          bottom: 0,
          marginRight: "16px",
          "&:after": {
            content: '""',
            position: "absolute",
            width: 157,
            height: 157,
            bgcolor: "#90caf9",
            borderRadius: "50%",
            top: -105,
            right: -96,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <List disablePadding sx={{ m: 0 }}>
            <ListItem alignItems="flex-start" disableGutters disablePadding>
              <ListItemAvatar sx={{ mt: 0 }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.largeAvatar,
                    color: "primary.main",
                    border: "none",
                    borderColor: "primary.main",
                    bgcolor: "background.paper",
                  }}
                >
                  <TableChartOutlinedIcon fontSize="inherit" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ mt: 0 }}
                primary={
                  <Typography variant="subtitle1" sx={{ color: "#1565c0" }}>
                    Current Job Running
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" sx={{ color: "#697586" }}>
                    {" "}
                    {
                      jobStatus?.filter((entry) => entry?.status === "RUNNING")
                        ?.length
                    }
                    /{jobStatus?.length} Entity
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <LinearProgressWithLabel
            value={
              jobStatus?.filter((entry) => entry?.status === "RUNNING")?.length
            }
          />
        </Box>
      </Card> */}
    </Box>
  );
};

export default SideBar;

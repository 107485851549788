import { Row, Col, Button } from "reactstrap";
import Blog from "../../components/dashboard/Blog";
import bg1 from "../../assets/images/bg/image (4).png";
import bg2 from "../../assets/images/bg/image (5).png";

const path = process.env.REACT_APP_ACE_AI;

const BlogData = [
  {
    title: "​Document Repo Creation",
    description:
      "​Create document repositories by adding documents and train them to embed with LLM for efficient organization and retrieval.",
    location: `${path}/repoView`,
    logo: bg1,
  },
  {
    title: "Generative Chat AI",
    description:
      "Experience precise answers from your documents with our AI model delivering factual extraction, summarization, and insights for enhanced interaction.",
    location: `${path}/chatView`,
    logo: bg2,
  },
];

const handleButtonClick = () => {
  const windowName = "RepoAI";
  const url = `${path}/home`;
  const existingWindow = window.open(url, windowName);

  if (existingWindow) {
    existingWindow.focus();
  }
};

const RepoAI = () => {
  // const openFeaturesPage = () => {
  //   const url = `${path}/home`;
  //   const tabName = 'RepoAI';

  //   // Attempt to open a new tab with a specific name
  //   const newTab = window.open(url, tabName);

  //   // Check if the new tab was successfully opened
  //   if (newTab) {
  //     newTab.focus();
  //   } else {
  //     // Fallback if the new tab couldn't be opened
  //     window.location.href = url;
  //   }
  // };
  const handleButtonClick = () => {
    const url = `${path}/home`;
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.append("login", true);
    window.open(urlWithToken.toString(), "RepoAI");
  };

  return (
    <div className="p-4 pt-2">
      <div className="d-flex justify-content-center">
        <h5 className="mb-3 me-5 fw-bold align-content-center">
          AceAI - Generative AI
        </h5>
      </div>
      <div className="d-flex justify-content-center text-secondary">
        <p>
          Enable business and IT to train custom LLMs on datasets, unlocking
          generative AI's full potential.
        </p>
      </div>

      <Row
        className="mb-4"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {BlogData.map((blg, index) => (
          <Col xs="12" sm="6" md="4" lg="3" key={index} className="mb-4">
            <Blog
              title={blg.title}
              text={blg.description}
              loc={blg.location}
              logo={blg.logo}
            />
          </Col>
        ))}
      </Row>

      <div className="row d-flex">
        <div className="d-flex justify-content-center me-5">
          <Button
            style={{
              color: "white",
              background: "#1E88E5FF",
              border: "none",
              padding: "6px 16px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
              fontWeight: 500,
              transition:
                "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              borderRadius: "4px",
              lineHeight: 1.75,
              fontSize: "0.875rem",
              textAlign: "center",
              boxSizing: "border-box",
              //  marginTop:"30px"
            }}
            // onClick={openFeaturesPage}
            onClick={() => handleButtonClick()}
          >
            View all features
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RepoAI;

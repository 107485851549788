import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import "./dataTable.css";
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import "@fortawesome/fontawesome-free/css/all.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bg1 from "../../assets/images/bg/valid.png";
import bg2 from "../../assets/images/bg/rule.png";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../layouts/loader/Loader";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataContext } from "../../DataContext";
import CreateETL from "./CreateETL";
const apiUrl = process.env.REACT_APP_API_URL;
const path = process.env.REACT_APP_ACE_DO;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const currentDate = new Date().toISOString();
const initColumn = {
  data_name: "",
  entity_name: "",
  source_type: "",
  source_details: {
    sourceMetadata: {
      tableOrder: 0,
      groupId: "",
      hasControlFile: false,
      source: "s3",
      cloud: {
        s3: {
          path: {
            s3Bucket: "",
            folder: "",
          },
          schemaType: "",
          dataFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
            filename: "",
          },
          controlFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
          },
          connectionDetails: {
            s3URI: "",
            authType: "",
            region: "",
            secretName: "",
          },
          fileTypeForMissingFileValidation: "",
        },
        azure: {
          path: {
            blobContainer: "",
            blobPath: "",
          },
          schemaType: "",
          dataFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
          },
          controlFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
          },
          connectionDetails: {
            storageAccount: "",
            blobURI: "",
            authType: "",
            secretName: "",
          },
          fileTypeForMissingFileValidation: "",
        },
      },
      streaming: {
        kafka: {
          bootstrapServers: "",
          partitions: null,
          replicationFactor: null,
          sourceTopic: "",
        },
      },
      database: {
        mongoDb: {},
        postgres: {
          TableDetails: {
            tableName: "",
            excludeTableName: [],
          },
          controlTableDetails: {
            tableName: "",
            excludeTableAffix: [],
          },
          databaseInfo: {
            databaseName: "",
            schemaName: "",
          },
        },
        oracleDb: {},
      },
    },
  },
  source_schema: {
    schema: {
      type: "struct",
      fields: [{ name: "", type: "text", nullable: "true" }],
    },
    constraints: {
      primarykeys: [],
    },
    extractDate: {
      isAvailable: false,
      columnName: null,
    },
  },
  target_type: "cassandra",
  target_details: {
    targetSystems: {
      snowflake: {
        snowFlakeTableName: "",
        tableType: "",
      },
      cassandra: {
        cassandraTableName: "",
        keyspace: "",
        tableType: "",
      },
    },
  },
  target_schema: {
    schema: {
      type: "struct",
      fields: [],
    },
    constraints: {
      primarykeys: [],
    },
    extractDate: {
      isAvailable: false,
      columnName: null,
    },
  },
  frequency: "",
  schedule: "0 5 * * 3",
  created_at: currentDate,
  modified_at: currentDate,
};

const Tables = () => {
  const tableRef = useRef(null);
  const datasetDataTableRef = useRef(null);
  const datasetTableRef = useRef(null);
  const navigate = useNavigate();
  const [selectedEle, setSelectedEle] = useState(null);
  const [etlData, setEtlData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState([]);
  const [clickedRow, setClickedRow] = useState(0);
  const [value, setValue] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [copySource, setCopySource] = useState(false);
  const [newSource, setNewSource] = useState(initColumn);
  const [newDuplicateError, setNewDuplicateError] = useState("");
  const [sourceToDelete, setSourceToDelete] = useState("");
  const [jobStatus, setJobStatus] = useState([]);
  const [loader, setLoader] = useState(true);
  const [Etlloader, setEtlLoader] = useState(false);
  const [stop, setStop] = useState(false);
  const [stopData, setStopData] = useState([]);
  const [sourceDuplicate, setSourceDuplicate] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [test, setTest] = useState([
    { Title: "title1", description: "description1" },
    { Title: "title2", description: "description2" },
    { Title: "title3", description: "description3" },
  ]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleChangeTable = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    width: "40%",
  };

  const changeClicked = (ele, index) => {
    const updatedEle = { ...ele, view: true };
    setSelectedEle(updatedEle);
    setTog(2);
    navigate("/createETL", { state: { ele: updatedEle } });
    setClickedRow(index);
  };

  const handleCloneClick = (ele) => {
    setSelectedEle(ele);
    navigate("/createETL", { state: { ele } });
  };

  useEffect(() => {
    if (window.location.pathname === "/ETL") setTog(0);
  }, []);

  const groupedData = etlData.reduce((acc, row) => {
    const group = row.data_name;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(row);
    return acc;
  }, {});

  const handleClick = async (ele, process) => {
    if (ele?.source_type == "streaming") {
      if (process == "start") {
        toast.success(
          "Initiating ETL job for " + ele?.data_name + " - " + ele?.entity_name
        );
      }
      try {
        setStop(false);
        let data = {
          data_name: ele?.data_name,
          entity_name: ele?.entity_name,
          source_type: ele?.source_type,
          action: process,
        };
        if (process == "stop") {
          toast.success(
            `Stopping the streaming job for ${data?.data_name} and ${data?.entity_name}. This may take a few moments...`
          );
        }
        const response = await axios.post(
          `${apiUrl}/metadata/streaming/`,
          data
        );
        if (process == "start") {
          toast.success("ETL job flow has been triggered successfully!");
        } else {
          toast.success("ETL job stopped successfully!");
        }
        getJobStatus();
        setTimeout(() => {
          getCurrentStatus();
        }, 5000);
      } catch (error) {
        console.error("Error running deployment:", error);
        toast.error(error?.response?.data?.error);
        setSourceDuplicate(false);
        setStop(false);
      }
    } else {
      toast.success(
        "Initiating ETL job for " + ele?.data_name + " - " + ele?.entity_name
      );
      try {
        let data = {
          data_name: ele?.data_name,
          entity_name: ele?.entity_name,
          source_type: ele?.source_type,
          load_type: ele?.load_type,
        };
        const response = await axios.post(
          `${apiUrl}/metadata/prefect_flow/start/`,
          data
        );
        toast.success("ETL job flow has been triggered successfully!");
        getJobStatus();
      } catch (error) {
        console.error("Error running deployment:", error);
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const handleDuplicate = (ele) => {
    let data = JSON.parse(JSON.stringify(ele));
    data.entity_name = "";
    setNewSource(data);
    setCopySource(true);
    setNewDuplicateError("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditSource = (ele) => {
    const updatedEle = { ...ele, edit: true };
    setSelectedEle(updatedEle);
    setTog(2);
    navigate("/createETL", { state: { ele: updatedEle } });
  };

  const handleDeleteSource = (ele) => {
    setSourceToDelete(ele);
    setDeleteModal(true);
  };

  const handleStop = (ele) => {
    setStopData(ele);
    setStop(true);
  };

  const handleModalClosed = () => {
    setDeleteModal(false);
  };

  const handleModalClosedETL = () => {
    setStop(false);
  };

  const handleConfirmDelete = async () => {
    setEtlLoader(true);
    try {
      const response = await axios.delete(
        // `${apiUrl}/metadata/source/${sourceToDelete?.data_name}/${sourceToDelete?.entity_name}/`
        `${apiUrl}/metadata/source/${sourceToDelete?.data_name}/${sourceToDelete?.entity_name}/${sourceToDelete?.source_type}/`
      );
      setDeleteModal(false);
      getSourceData();
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      toast.success("Source deleted successfully");
      setEtlLoader(false);
    } catch (error) {
      setDeleteModal(false);
      setEtlLoader(false);
      console.error("Error communicating with the API:", error.message);
      toast.error(error?.response?.data?.error);
    }
  };

  const handleCreateSource = async () => {
    if (newSource && newSource?.entity_name) {
      setSourceDuplicate(true);
      setNewSource((source) => ({
        ...source,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
      }));
      try {
        setEtlLoader(true);
        setCopySource(false);
        const response = await axios.post(
          `${apiUrl}/metadata/source/`,
          newSource
        );
        let data = response?.data;
        toast.success(data?.message);
        setTog(0);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        getSourceData();
        setCopySource(false);
        setSourceDuplicate(false);
        setEtlLoader(false);
      } catch (error) {
        setSourceDuplicate(false);
        setEtlLoader(false);
        console.error("Failed to fetch data:", error);
        toast.error(error?.response?.data?.error);
      }
    } else {
      setNewDuplicateError("Please fill the Entity name");
    }
  };

  const getTemplateData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/metadata/template_source/allsources/`
      );
      let data = response?.data;
      console.log(data);
      setTemplateData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getCurrentStatus = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}//metadata/streaming/jobs/status/`
      );
      let data = response?.data;
      console.log(data);
      setCurrentStatus(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getSourceData = async () => {
    try {
      // setEtlLoader(true);
      const response = await axios.get(`${apiUrl}/metadata/source/`);
      let data = response?.data;
      console.log(data);
      setEtlData(data);
      setEtlLoader(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getJobStatus = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/metadata/prefect_flow/jobs/history/`
      );
      let data = response?.data;
      console.log(data);
      setJobStatus(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (value == "1") {
      getJobStatus();
      const intervalId = setInterval(() => {
        getJobStatus();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [value]);
  useEffect(() => {
    if (value == "0") {
      getCurrentStatus();
      const intervalId = setInterval(() => {
        getCurrentStatus();
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, []);

  const customStyles = {
    table: {
      style: {
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        fontSize: "14px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#FFF",
        borderBottom: "1px solid #E0E0E0",
        fontSize: "0.875rem",
        // padding: "24px 10px",
        // position: 'sticky',
        // top: 0,
        // zIndex: 10,
        fontWeight: "600",
      },
    },
    rows: {
      style: {
        minHeight: "48px",
        borderBottom: "0.5px solid #E0E0E0",
        "&:nth-of-type": {
          backgroundColor: "#F9F9F9",
        },
        "&:hover": {
          backgroundColor: "#F1F1F1",
        },
      },
    },
    cells: {
      style: {
        fontSize: "14px !important",
        // padding: "10px  10px !important",
        color: "#333",
      },
    },
    pagination: {
      style: {
        fontSize: "14px",
        color: "#333",
        borderRadius: "0px 0px 12px 12px",
      },
    },
  };

  const clickRow = async (ele, type) => {
    try {
      let data = {
        entity_name: ele?.entity_name,
        source_type: ele?.source_type == "cloud" ? "file" : ele?.source_type,
        source_type: ele?.source_type == "cloud" ? "file" : ele?.source_type,
        entity_group: ele?.data_name?.toLowerCase(),
      };
      const jsonData = JSON.stringify(data);
      const encoder = new TextEncoder();
      const binaryData = encoder.encode(jsonData);
      const base64Data = btoa(String.fromCharCode(...binaryData));
      if (type == "source") {
        var baseUrl = path;
      } else {
        var baseUrl = `${path}/RawDataRules`;
      }
      if (type == "source") {
        var baseUrl = path;
      } else {
        var baseUrl = `${path}/RawDataRules`;
      }
      const url = `${baseUrl}?data=${encodeURIComponent(base64Data)}`;
      window.open(url, "AceDo");
    } catch (error) {
      console.error("Encoding failed", error);
    }
  };

  useEffect(() => {
    getSourceData();
    // getTemplateData();
    getJobStatus();
  }, []);

  const headers = jobStatus?.length > 0 ? Object.keys(jobStatus[0]) : [];

  const columns = [
    // {
    //   name: "GROUP",
    //   selector: (row) => row.data_name,
    //   style: { fontSize: "16px" },
    //   sortable: true,
    //   width: "17% !important",
    // },
    {
      name: "ENTITY NAME",
      selector: (row) => row.entity_name,
      style: { fontSize: "16px" },
      sortable: true,
      width: "20% !important",
    },
    {
      name: "TYPE",
      selector: (row) =>
        row.source_type === "cloud" ? "File" : row.source_type,
      style: {
        fontSize: "16px",
        textTransform: "capitalize",
      },
      sortable: true,
      width: "15% !important",
    },
    {
      name: "SOURCE",
      selector: (row) =>
        row.source_details?.sourceMetadata?.source === "postgres"
          ? "Oracle"
          : row.source_details?.sourceMetadata?.source,
      style: {
        fontSize: "16px",
        textTransform: "capitalize",
      },
      sortable: true,
      width: "15% !important",
    },
    {
      name: "TARGET",
      selector: (row) =>
        row.entity_name === "documents" ? "AceVector" : "AceData",
      style: {
        fontSize: "16px",
        textTransform: "capitalize",
      },
      sortable: true,
      width: "15% !important",
    },
    {
      name: "AceDO",
      width: "15% !important",
      cell: (row) => (
        <div
          className="text-center"
          style={{
            display: "flex",
            // gap: "3px",
            // gap: "3px",
            justifyContent: "center",
            marginRight: "3%",
          }}
        >
          <Tooltip title="Source Validation" placement="top">
            <img
              src={bg1}
              alt="Source Validation"
              onClick={(event) => {
                event.stopPropagation();
                clickRow(row, "source");
              }}
              style={{ cursor: "pointer", height: "28px", width: "28px" }}
            />
          </Tooltip>
          <Tooltip title="Rules Validation" placement="top">
            <img
              src={bg2}
              alt="Rules Validation"
              onClick={(event) => {
                event.stopPropagation();
                clickRow(row, "rules");
              }}
              style={{ cursor: "pointer", height: "32px", width: "32px" }}
            />
          </Tooltip>
        </div>
      ),
      style: {
        paddingLeft: "7px",
      },
    },
    {
      name: "ACTION",
      width: "15% !important",
      cell: (row) => (
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <Tooltip title="Edit" placement="top">
            <i
              className="fa-solid fa-pen text-primary"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                handleEditSource(row);
              }}
            ></i>
          </Tooltip>
          <Tooltip title="Duplicate" placement="top">
            <i
              className="fa-solid fa-copy text-secondary"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                handleDuplicate(row);
              }}
            ></i>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <i
              className="fa-solid fa-trash text-danger"
              style={{ cursor: "pointer", color: "#976565" }}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteSource(row);
              }}
            ></i>
          </Tooltip>

          {row.source_type !== "streaming" && (
            <Tooltip title="Start" placement="top">
              <i
                className="fa-solid fa-play text-success"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(row, "start");
                }}
              ></i>
            </Tooltip>
          )}
          {row.source_type === "streaming" && (
            <>
              {currentStatus.some(
                (status) =>
                  status.key_name === `${row.data_name}_${row.entity_name}`
              ) ? (
                <Tooltip title="Stop" placement="top">
                  <i
                    className="fa-solid fa-stop"
                    style={{ cursor: "pointer", color: "#F76161" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleStop(row);
                    }}
                  ></i>
                </Tooltip>
              ) : (
                <Tooltip title="Start" placement="top">
                  <i
                    className="fa-solid fa-play text-success"
                    style={{ cursor: "pointer" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(row, "start");
                    }}
                  ></i>
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
      style: {
        paddingLeft: "7px",
      },
    },
  ];

  const columnJob = [
    {
      name: "JOB ID",
      selector: (row) => row.job_id,
      style: { fontSize: "16px", width: "40%" },
      sortable: true,
    },
    {
      name: "JOB NAME",
      selector: (row) => row.job_name,
      style: { fontSize: "16px", width: "25%" },
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      style: { fontSize: "16px", width: "20%" },
      sortable: true,
    },
    {
      name: "TIME",
      selector: (row) => row.when,
      style: { fontSize: "16px", width: "15%" },
      sortable: true,
    },
  ];
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const { tog, setTog } = useContext(DataContext);

  const [searchQuery, setSearchQuery] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    const selectedTitle = row.Title;

    const displayData = groupedData[selectedTitle];

    console.log("Formatted data for:", selectedTitle, displayData);
    setSelectedData(displayData);

    setTog(1);
  };

  const formattedData = Object.keys(groupedData).map((key) => {
    console.log("sdfsd", groupedData[key], key);

    return {
      Title: key,
      data: groupedData[key],
      description: groupedData[key]?.[0]?.group_description,
    };
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredData = formattedData.filter((row) => {
    return Object.values(row).some((val) =>
      val.toString().toLowerCase().includes(searchQuery)
    );
  });

  const columnTest = [
    {
      name: "Name",
      selector: (row) => row.Title,
      style: { fontSize: "16px" },
      sortable: true,
      width: "20%",
    },
    {
      name: "Description",
      selector: (row) => (
        <Tooltip title={row.description} onClick={() => handleRowClick(row)}>
          {row.description}
        </Tooltip>
      ),
      style: { fontSize: "16px" },
      sortable: true,
      // width: "50%",
    },
    // {
    //   name: "View Group",
    //   selector: (row) => (
    //     <Tooltip title="View" onClick={() => handleRowClick(row)}>
    //       <IconButton
    //         style={{ backgroundColor: "rgb(30, 136, 229)", color: "white" }}
    //       >
    //         <ChevronRightIcon />
    //       </IconButton>
    //     </Tooltip>
    //   ),
    //   style: { fontSize: "16px" },
    //   sortable: false,
    // },
  ];

  const handleDescriptionChange = (e) => {
    const updatedDescription = e.target.value;
    const updatedData = [...selectedData];
    if (updatedData[0]) {
      updatedData[0].group_description = updatedDescription;
    }
    setSelectedData(updatedData);
  };

  const handleSave = async () => {
    if (!selectedData[0]?.group_description.trim()) {
      setNewDuplicateError("Description cannot be empty.");
      return;
    }
    setNewDuplicateError("");
    try {
      const data = {
        group_description: selectedData[0]?.group_description,
      };
      const response = await axios.put(
        `${apiUrl}/metadata/update-group-description/${selectedData[0]?.data_name}/`,
        data
      );

      if (response.status === 200) {
        toast.success("Description saved successfully!");
        setTog(0);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      console.error("Error saving description:", error);
      setTog(0);
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div>
      {Etlloader ? (
        <div
          className="position-relative"
          style={{ paddingTop: "15rem", paddingBottom: "2rem" }}
        >
          <div className="backdrop"></div>
          <div className="spinner-container">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          {tog === 0 && (
            <div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "24px",
                  display: isMobile ? "" : "flex",
                  borderRadius: "12px 12px 0px 0px",
                  borderBottom: "1px solid #E0E0E0",
                  justifyContent: isMobile ? "" : "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
                  Groups
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "row",
                    gap: isMobile ? "16px" : "8px",
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "9px !important",
                        borderRadius: "12px !important",
                      },
                    }}
                    value={searchQuery}
                    placeholder="Search"
                    onChange={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    style={{
                      border: "none",
                      backgroundColor: "#1E88E5",
                      color: "#FFF",
                      boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
                      lineHeight: "1.75",
                      fontSize: "0.875rem",
                      minWidth: "120px",
                    }}
                    onClick={() => {
                      setTog(2);
                    }}
                    className="ms-2"
                    variant="contained"
                  >
                    Create Source
                  </Button>
                </div>
              </div>

              {filteredData.length > 0 ? (
                <DataTable
                  columns={columnTest}
                  data={filteredData}
                  pagination
                  paginationPerPage={15}
                  paginationRowsPerPageOptions={[5, 10, 25, 50]}
                  pointerOnHover
                  fixedHeader={true}
                  fixedHeaderScrollHeight="80vh"
                  onRowClicked={
                    (row, event) => handleRowClick(row)
                    // changeClicked(row, event.currentTarget.dataset.index)
                  }
                  noDataComponent={
                    <div style={{ padding: "24px", textAlign: "center" }}>
                      <Typography variant="h6">No data found</Typography>
                    </div>
                  }
                  customStyles={customStyles}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "24px",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6">No data found</Typography>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {tog === 1 && selectedData && (
        <div>
          <div
            style={{
              backgroundColor: "#fff",
              display: "flex",
              padding: "24px",
              alignItems: "center",
              borderRadius: "12px 12px 0px 0px",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Tooltip title="Back" onClick={() => setTog(0)}>
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>

            <Typography variant={isMobile ? "h4" : "h3"}>
              <span
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                {/* Group */}
              </span>
              {selectedRow?.Title}
            </Typography>
          </div>

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #E0E0E0",
              padding: "5px 16px",
            }}
          >
            <Tab label="Source Data" />
            <Tab label="Group Settings" />
          </Tabs>

          <Box>
            {tabValue === 0 && (
              <div>
                {selectedData.length > 0 ? (
                  <>
                    {" "}
                    <DataTable
                      columns={columns}
                      data={selectedData}
                      pagination
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[5, 10, 25, 50]}
                      pointerOnHover
                      fixedHeader={true}
                      fixedHeaderScrollHeight="450px"
                      onRowClicked={(row, event) =>
                        changeClicked(row, event.currentTarget.dataset.index)
                      }
                      customStyles={customStyles}
                    />
                    <Modal
                      open={deleteModal}
                      onClose={() => setDeleteModal(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={modelStyle}
                        style={{ width: isMobile ? "90%" : "30%" }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{
                            color: "black",
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                          }}
                        >
                          <Typography
                            variant={isMobile ? "h4" : "h4"}
                            gutterBottom
                          >
                            Delete Source
                          </Typography>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center p-3">
                          <p>Are you sure you want to delete?</p>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center pb-3">
                          <button
                            className=" ms-5 me-4 form-control "
                            onClick={() => handleModalClosed()}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = "#e0e0e0")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = "#f0f0f0")
                            }
                          >
                            No
                          </button>
                          <button
                            className="form-control me-5 "
                            style={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "1px solid #007bff",
                              transition: "background-color 0.3s",
                            }}
                            onClick={() => handleConfirmDelete()}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = "#0056b3")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = "#007bff")
                            }
                          >
                            Yes
                          </button>
                        </div>
                      </Box>
                    </Modal>
                    <Modal
                      open={copySource}
                      onClose={() => setCopySource(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={modelStyle}
                        style={{ width: isMobile ? "70%" : "40%" }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          p={2}
                          sx={{
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                            height: "40px",
                          }}
                        >
                          <Typography
                            variant={isMobile ? "h4" : "h4"}
                            sx={{ mt: 3, px: 2 }}
                            gutterBottom
                          >
                            Duplicate Source
                          </Typography>
                        </Box>

                        <Grid container spacing={2} sx={{ mt: 3, px: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography>Data Name</Typography>
                            <input
                              type="text"
                              className="form-control"
                              name="data_name"
                              disabled
                              value={newSource?.data_name}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography>
                              Entity Name <span className="text-danger">*</span>
                            </Typography>
                            <input
                              type="text"
                              className="form-control"
                              name="entity_name"
                              value={newSource?.entity_name}
                              onChange={(event) => {
                                const { name, value } = event.target;
                                setNewSource((prevSource) => ({
                                  ...prevSource,
                                  [name]: value,
                                }));
                                setNewDuplicateError("");
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography>Source Type</Typography>
                            <input
                              type="text"
                              className="form-control"
                              name="data_name"
                              disabled
                              value={newSource?.source_type}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography>Source Details</Typography>
                            <input
                              type="text"
                              className="form-control"
                              name="entity_name"
                              disabled
                              value={
                                newSource?.source_details?.sourceMetadata
                                  ?.source === "postgres"
                                  ? "Oracle"
                                  : newSource?.source_details?.sourceMetadata
                                      ?.source
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography>Target Type</Typography>
                            <input
                              type="text"
                              className="form-control"
                              name="target_type"
                              disabled
                              value={newSource?.target_type}
                            />
                          </Grid>
                        </Grid>

                        <Box
                          display="flex"
                          justifyContent="end"
                          alignItems="center"
                          pb={3}
                          mt={3}
                        >
                          {newDuplicateError && (
                            <Typography
                              className="text-danger"
                              sx={{ mr: 3, pt: 1 }}
                            >
                              {newDuplicateError}
                            </Typography>
                          )}
                          <Box>
                            <button
                              className="form-control me-5 "
                              style={{
                                backgroundColor: "#007bff",
                                color: "#fff",
                                width: "fit-content",
                                border: "1px solid #007bff",
                                transition: "background-color 0.3s",
                                marginLeft: isMobile ? "" : "23%",
                              }}
                              onClick={handleCreateSource}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = "#0056b3")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = "#007bff")
                              }
                            >
                              Duplicate
                            </button>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                    <Modal
                      open={stop}
                      onClose={() => setStop(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={modelStyle}
                        style={{ width: isMobile ? "90%" : "30%" }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{
                            color: "black",
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                          }}
                        >
                          <Typography
                            variant={isMobile ? "h4" : "h4"}
                            gutterBottom
                          >
                            Stop ETL
                          </Typography>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center p-3">
                          <p>Are you sure you want to stop the ETL job?</p>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center pb-3">
                          <button
                            className=" ms-5 me-4 form-control "
                            onClick={() => handleModalClosedETL()}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = "#e0e0e0")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = "#f0f0f0")
                            }
                          >
                            No
                          </button>
                          <button
                            className="form-control me-5 "
                            style={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "1px solid #007bff",
                              transition: "background-color 0.3s",
                            }}
                            onClick={() => handleClick(stopData, "stop")}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = "#0056b3")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = "#007bff")
                            }
                          >
                            Yes
                          </button>
                        </div>
                      </Box>
                    </Modal>
                  </>
                ) : (
                  <div style={{ padding: "24px", textAlign: "center" }}>
                    <Typography variant="h6">No data found</Typography>
                  </div>
                )}
              </div>
            )}
            {tabValue === 1 && (
              <div
                style={{
                  padding: "24px",
                  backgroundColor: "#fff",
                }}
              >
                <div className={`${isMobile ? "" : "row"} mt-2`}>
                  <div className={`${isMobile ? "" : "col-2"} mt-2 fw-bold`}>
                    Name :
                  </div>
                  <div className={`${isMobile ? "mt-2" : "col-10"}`}>
                    <div className="row">
                      <div className="col-12">
                        <input
                          className="form-control"
                          placeholder="Enter Source Name"
                          value={selectedData[0]?.data_name}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${isMobile ? "" : "row"} mt-4`}>
                  <div className={`${isMobile ? "" : "col-2"} mt-2 fw-bold`}>
                    Description &nbsp;&nbsp;&nbsp;:
                  </div>
                  <div className={`${isMobile ? "mt-2" : "col-10"}`}>
                    <div className="row">
                      <div className="col-12">
                        <textarea
                          className="form-control"
                          style={{ minHeight: "100px" }}
                          value={selectedData[0]?.group_description || ""}
                          onChange={handleDescriptionChange}
                          placeholder="Enter description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Box
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  pb={3}
                  mt={3}
                >
                  {newDuplicateError && (
                    <Typography className="text-danger" sx={{ mr: 3, pt: 1 }}>
                      {newDuplicateError}
                    </Typography>
                  )}
                  <Box>
                    <button
                      className="form-control me-5 mt-2"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        width: "fit-content",
                        border: "1px solid #007bff",
                        transition: "background-color 0.3s",
                        marginLeft: isMobile ? "20%" : "50%",
                      }}
                      onClick={handleSave}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "#0056b3")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#007bff")
                      }
                    >
                      Save
                    </button>
                  </Box>
                </Box>
              </div>
            )}
          </Box>
        </div>
      )}
      {tog === 2 && (
        <div>
          <CreateETL setTog={setTog} tog={tog} />
        </div>
      )}
    </div>
  );
};

export default Tables;

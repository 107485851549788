import logo from '../assets/images/logos/Bleubird.png'
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link>
      <img src={logo} width="140" height="45"></img>
    </Link>
  );
};

export default Logo;

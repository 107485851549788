import React, { useContext } from "react";
import BasicTabs from "./CreateTabination";
import { useLocation } from "react-router-dom";
import { DataContext } from "../../DataContext";

function CreateETL() {
  const { tog, setTog } = useContext(DataContext);

  const location = useLocation();
  const ele = location.state?.ele;
  return (
    <>
      <div className="p-0">
        <BasicTabs ele={ele} setTog={setTog} tog={tog} />
      </div>
    </>
  );
}

export default CreateETL;

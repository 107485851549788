import React, { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [selectedEle, setSelectedEle] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [ container, setContainer] = useState(null);
  const [containerAdd, setContainerAdd] = useState(null);
  const [newChat, setNewChat] = useState(null);
  const [tog, setTog] = useState(0);

  return (
    <DataContext.Provider
      value={{
        selectedEle,
        setSelectedEle,
        selectedChatId,
        setSelectedChatId,
        containerAdd,
        setContainerAdd,
        container,
        setContainer,
        newChat,
        setNewChat,
        setTog,
        tog,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

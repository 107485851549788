import React from "react";
import { DataProvider } from "./DataContext";
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { ToastContainer } from "react-toastify";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";

const App = () => {
  const routing = useRoutes(Themeroutes);

  return (
    <ThemeProvider theme={theme({})}>
      <DataProvider>
        <ToastContainer position="top-center" />
        {routing}
      </DataProvider>
    </ThemeProvider>
  );
};

export default App;

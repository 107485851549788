import React,{ Suspense, lazy } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { CircularProgress, Box } from '@mui/material';
 
import bg1 from '../../assets/images/bg/image (1).png';
import bg2 from '../../assets/images/bg/image (2).png';
import bg3 from '../../assets/images/bg/image (3).png';
const Blog = lazy(() => import('../../components/dashboard/Blog'));
 
const path = process.env.REACT_APP_ACE_DO;
 
const BlogData = [
  {
    title: 'Data Source Summary',
    description:
      'A Data Source Summary provides a concise overview of data within the source, highlighting key metrics and essential insights.',
    location: `${path}/`,
    logo: bg1,
  },
  {
    title: 'Data Validations',
    description:
      'Democratize Rule authoring with UI driven flows that make it easy for business users to author new rules',
    location: `${path}/RawDataRules`,
    logo: bg2,
  },
  {
    title: 'Data Reliability',
    description:
      'Data reliability ensures the accuracy, consistency, and dependability of data in a source, enhancing decision-making and operational efficiency.',
    location: `${path}/DataReliability`,
    logo: bg3,
  },
];
 
const AceDO = () => {
  return (
    
    <div className="p-4 pt-2">
      <div  style={{display:"flex",justifyContent:"center"}}>
        <h5 className="mb-3 me-5 fw-bold align-content-center">
          AceDO - Data Observability
        </h5>
      </div>
      <div className="d-flex justify-content-center text-secondary" style={{display:"flex",justifyContent:"center",color:"#6c757d"}}>
        <p>
          A data validation tool verifies accuracy, consistency, and integrity
          by checking data against predefined rules.
        </p>
      </div>
      <Suspense fallback={<Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>}>
        <Row className="mb-4" style={{display:"flex",justifyContent:"center",marginBottom:"20px"}}>
          {BlogData.map((blg, index) => (
            <Col xs="12" sm="6" md="4" lg="3" key={index} className="mb-4">
              <Blog
                title={blg.title}
                text={blg.description}
                loc={blg.location}
                logo={blg.logo}
              />
            </Col>
          ))}
        </Row>
      </Suspense>
      <div className="row d-flex">
        <div className="d-flex justify-content-center me-5" style={{display:"flex",justifyContent:"center",marginRight:"20px"}}>
          <Button
            style={{
              color: "white",
              background: "#1E88E5FF",
              border: "none",
              padding:"6px 16px",
              boxShadow:"rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
              fontWeight:500,
              transition:"background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              borderRadius:"4px",
              lineHeight:1.75,
              fontSize:"0.875rem",
              textAlign:"center",
              boxSizing:"border-box",
              
             
            }}
        
            onClick={() => {
              window.open(`${path}/`, "AceDo");
            }}
          >
            View all features
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AceDO;
 
 
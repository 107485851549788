import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Card, Button } from "reactstrap";
import { Chip, Grid, Divider } from "@mui/material";
import Select from "react-select";
import CronTab from "./CronTab.jsx";
import "./main.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../layouts/loader/Loader.js";
import { useMediaQuery, useTheme } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { DataContext } from "../../DataContext.js";
const apiUrl = process.env.REACT_APP_API_URL;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs(props) {
  const { tog, setTog } = useContext(DataContext);
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([0]);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentDate = new Date().toISOString();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const initColumn = {
    data_name: "",
    entity_name: "",
    source_type: "",
    load_type: "hdfsLoad",
    group_description: "",
    source_details: {
      sourceMetadata: {
        tableOrder: null,
        groupId: "",
        hasControlFile: false,
        source: "s3",
        cloud: {
          s3: {
            path: {
              s3Bucket: "",
              folder: "",
            },
            schemaType: "",
            dataFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
              filename: "",
            },
            controlFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
            },
            connectionDetails: {
              s3URI: "",
              authType: "",
              region: "",
              secretName: "",
            },
            fileTypeForMissingFileValidation: "",
          },
          azure: {
            path: {
              blobContainer: "",
              blobPath: "",
            },
            schemaType: "",
            dataFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
            },
            controlFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
            },
            connectionDetails: {
              storageAccount: "",
              blobURI: "",
              authType: "",
              secretName: "",
            },
            fileTypeForMissingFileValidation: "",
          },
        },
        streaming: {
          kafka: {
            bootstrapServers: "",
            partitions: null,
            replicationFactor: null,
            sourceTopic: "",
          },
        },
        database: {
          mongoDb: {},
          postgres: {
            TableDetails: {
              tableName: "",
              excludeTableName: [],
            },
            controlTableDetails: {
              tableName: "",
              excludeTableAffix: [],
            },
            databaseInfo: {
              databaseName: "",
              schemaName: "",
            },
          },
          oracleDb: {},
        },
      },
    },
    source_schema: {
      schema: {
        type: "struct",
        fields: [{ name: "", type: "text", nullable: true }],
      },
      constraints: {
        primarykeys: [],
      },
      extractDate: {
        isAvailable: false,
        columnName: null,
      },
    },
    target_type: "cassandra",
    target_details: {
      targetSystems: {
        snowflake: {
          snowFlakeTableName: "",
          tableType: "",
        },
        cassandra: {
          cassandraTableName: "",
          keyspace: "",
          tableType: "",
        },
      },
    },
    target_schema: {
      schema: {
        type: "struct",
        fields: [],
      },
      constraints: {
        primarykeys: [],
      },
      extractDate: {
        isAvailable: false,
        columnName: null,
      },
    },
    frequency: "DAILY",
    schedule: "* * * * *",
    created_at: currentDate,
    modified_at: currentDate,
  };
  const [cronValue, setCronValue] = useState("");
  const [newSource, setNewSource] = useState(initColumn);
  const [metaDataRequired, setMetaDataRequired] = useState("");
  const [sourceDetailsRequired, setSourceDetailsRequired] = useState("");
  const [schemaRequired, setSchemaRequired] = useState("");
  const isFirstRender = useRef(true);
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [sourceLoader, setSourceLoader] = useState(false);
  const [etlData, setEtlData] = useState([]);
  const [match, setMatch] = useState(false);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const [partitionKey, setPartitionKey] = useState([]);
  const [clusteringKey, setClusteringKey] = useState([]);

  const options = newSource?.source_schema?.schema?.fields.map((ele) => ({
    label: ele?.name,
    value: ele?.name,
  }));

  const handlePartitionChange = (selectedOptions) => {
    setPartitionKey(selectedOptions || []);
  };

  const handleClusteringChange = (selectedOptions) => {
    setClusteringKey(selectedOptions || []);
  };

  const handlePartitionChipDelete = (chipToDelete) => {
    setPartitionKey((prev) =>
      prev.filter((selected) => selected.value !== chipToDelete.value)
    );
  };

  const handleClusteringChipDelete = (chipToDelete) => {
    setClusteringKey((prev) =>
      prev.filter((selected) => selected.value !== chipToDelete.value)
    );
  };

  const clusteringKeyOptions = options.filter(
    (option) =>
      !partitionKey.some((selected) => selected.value === option.value)
  );

  const partitionKeyOptions = options.filter(
    (option) =>
      !clusteringKey.some((selected) => selected.value === option.value)
  );

  const combinedKeys = [
    { ...partitionKey.map((item) => item.value) },
    { ...clusteringKey.map((item) => item.value) },
  ];

  useEffect(() => {
    setNewSource((prevSource) => {
      if (prevSource) {
        const currentPrimaryKeys =
          prevSource.source_schema.constraints.primarykeys;
        if (
          JSON.stringify(combinedKeys) !== JSON.stringify(currentPrimaryKeys)
        ) {
          return {
            ...prevSource,
            source_schema: {
              ...prevSource.source_schema,
              constraints: {
                ...prevSource.source_schema.constraints,
                primarykeys: combinedKeys,
              },
            },
          };
        }
      }
      return prevSource;
    });
  }, [combinedKeys, setNewSource]);

  const handleNextTab = () => {
    const nextValue = value + 1;
    if (value == 0) {
      if (
        newSource?.data_name &&
        newSource?.entity_name &&
        newSource?.source_type &&
        newSource?.load_type
      ) {
        setMetaDataRequired("");
        setValue(nextValue);
        setNewSource((prevSource) => ({
          ...prevSource,
          target_details: {
            ...prevSource.target_details,
            targetSystems: {
              ...prevSource.target_details.targetSystems,
              cassandra: {
                ...prevSource.target_details.targetSystems.cassandra,
                cassandraTableName: `${prevSource.data_name}_${prevSource.entity_name}`,
              },
            },
          },
        }));
      } else {
        setMetaDataRequired("Please fill all required fields.");
      }
    }
    if (value == 1) {
      let isValid = false;
      let errorMessage = "Please fill all required fields.";

      if (newSource?.source_type == "database") {
        const databaseInfo =
          newSource?.source_details?.sourceMetadata?.database?.postgres
            ?.databaseInfo;
        if (databaseInfo?.databaseName && databaseInfo?.schemaName) {
          isValid = true;
        }
      } else if (newSource?.source_type == "streaming") {
        const kafka =
          newSource?.source_details?.sourceMetadata?.streaming?.kafka;
        if (kafka?.bootstrapServers && kafka?.sourceTopic) {
          isValid = true;
        }
      } else {
        const source = newSource?.source_details?.sourceMetadata?.source;
        const s3 = newSource?.source_details?.sourceMetadata?.cloud?.s3?.path;
        const dataFile =
          newSource?.source_details?.sourceMetadata?.cloud?.s3?.dataFile;
        if (source == "azure") {
          const azurePath =
            newSource?.source_details?.sourceMetadata?.cloud?.azure?.path;
          const azureData =
            newSource?.source_details?.sourceMetadata?.cloud?.azure?.dataFile;
          if (azurePath?.blobContainer && azurePath?.blobPath) {
            isValid = true;
          }
          if (
            (azureData?.header &&
              azureData?.fileType &&
              azureData?.fileType == "pdf") ||
            (azureData?.delimiter && azureData?.filename)
          ) {
            isValid = true;
          }
          const metadata = newSource?.source_details?.sourceMetadata;
          if (
            azureData?.fileType == "pdf" ||
            (metadata?.tableOrder &&
              metadata?.hasControlFile &&
              azureData?.fileType == "pdf") ||
            newSource?.source_details?.sourceMetadata?.cloud?.azure?.schemaType
          ) {
            isValid = true;
          }
        } else if (source == "s3") {
          if (s3?.s3Bucket && s3?.folder) {
            isValid = true;
          }
          console.log(s3);
          console.log(dataFile);
          if (
            (dataFile?.header &&
              dataFile?.fileType &&
              dataFile?.fileType == "pdf") ||
            (dataFile?.delimiter && dataFile?.filename)
          ) {
            isValid = true;
          }
          const metadata = newSource?.source_details?.sourceMetadata;
          console.log(metadata, s3);
          if (
            dataFile?.fileType == "pdf" ||
            (metadata?.tableOrder &&
              metadata?.hasControlFile &&
              dataFile?.fileType == "pdf") ||
            newSource?.source_details?.sourceMetadata?.cloud?.s3?.schemaType
          ) {
            isValid = true;
          }
        }
      }
      if (isValid) {
        setSourceDetailsRequired("");
        setValue(nextValue);
      } else {
        setSourceDetailsRequired(errorMessage);
      }
    }
    if (value == 2) {
      let isValid = false;
      let errorMessage = "Please fill all required fields.";
      newSource?.source_schema?.schema?.fields.forEach((ele) => {
        if (
          (ele?.name && ele?.type && ele?.nullable !== undefined) ||
          newSource?.source_details?.sourceMetadata?.cloud?.azure?.controlFile
            ?.fileType == "pdf" ||
          newSource?.source_details?.sourceMetadata?.cloud?.s3?.dataFile
            ?.fileType == "pdf"
        ) {
          isValid = true;
        }
      });
      if (isValid) {
        setSchemaRequired("");
        setValue(nextValue);
      } else {
        setSchemaRequired(errorMessage);
      }
    }
  };

  const addRow = () => {
    const newField = { name: "", type: "text", nullable: true };
    setNewSource((prevState) => ({
      ...prevState,
      source_schema: {
        ...prevState.source_schema,
        schema: {
          ...prevState.source_schema.schema,
          fields: [...prevState.source_schema.schema.fields, newField],
        },
      },
    }));
  };

  const handleFieldChange = (event, index, fieldName) => {
    const { value } = event.target;
    const booleanValue =
      value === "true" ? true : value === "false" ? false : value;
    let updatedSource = { ...newSource };
    let fieldToUpdate = updatedSource.source_schema.schema.fields[index];
    fieldToUpdate[fieldName] = booleanValue;
    setNewSource(updatedSource);
    setSchemaRequired("");
  };

  const removeRow = (indexToRemove) => {
    setNewSource((prevState) => ({
      ...prevState,
      source_schema: {
        ...prevState.source_schema,
        schema: {
          ...prevState.source_schema.schema,
          fields: prevState.source_schema.schema.fields.filter(
            (_, index) => index !== indexToRemove
          ),
        },
      },
    }));
  };

  const getSourceData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/metadata/source/`);
      let data = response?.data;
      setEtlData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const compareDataName = (e) => {
    var { name, value } = e.target;
    if (name == "data_name") {
      const matchedEntity = etlData?.find((ele) => ele.data_name === value);
      if (matchedEntity) {
        const matchedEntity = etlData?.find((ele) => ele.data_name === value);
        setMatch(true);
        console.log(matchedEntity?.group_description);
        setNewSource((prevSource) => ({
          ...prevSource,
          group_description: matchedEntity.group_description,
        }));
      } else {
        setMatch(false);
        var updatedSource = { ...newSource };
        updatedSource["group_description"] = value;
        setNewSource(updatedSource);
      }
    }
  };

  const handleInputChange = (event, path) => {
    const { name, value } = event.target;
    if (path == "sourceMetadata") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata[name] = value;
    } else if (path == "s3Path") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.path[name] = value;
    } else if (path == "azurePath") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.path[name] =
        value;
    } else if (path == "dataFile") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.dataFile[name] =
        value;
      if (value == "pdf") {
        updatedSource.source_details.sourceMetadata.cloud.s3["schemaType"] = "";
        updatedSource.source_details.sourceMetadata["tableOrder"] = "";
        updatedSource.source_details.sourceMetadata.cloud.s3.dataFile[
          "excludeFileNameAffix"
        ] = "";
        updatedSource.source_details.sourceMetadata.cloud.s3.dataFile[
          "delimiter"
        ] = "";
        updatedSource.source_schema.schema["fields"] = [
          { name: "", type: "", nullable: "" },
        ];
      }
    } else if (path == "controlFile") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.controlFile[name] =
        value;
    } else if (path == "connection") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.connectionDetails[
        name
      ] = value;
    } else if (path == "dataFileAzure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.dataFile[name] =
        value;
      if (value == "pdf") {
        updatedSource.source_details.sourceMetadata.cloud.azure["schemaType"] =
          "";
        updatedSource.source_details.sourceMetadata["tableOrder"] = "";
        updatedSource.source_details.sourceMetadata.cloud.azure.dataFile[
          "excludeFileNameAffix"
        ] = "";
        updatedSource.source_details.sourceMetadata.cloud.azure.dataFile[
          "delimiter"
        ] = "";
        updatedSource.source_schema.schema["fields"] = [
          { name: "", type: "", nullable: "" },
        ];
      }
    } else if (path == "controlFileAzure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.controlFile[
        name
      ] = value;
    } else if (path == "connectionAzure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.connectionDetails[
        name
      ] = value;
    } else if (path == "s3") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3[name] = value;
    } else if (path == "azure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure[name] = value;
    } else if (path == "kafka") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.streaming.kafka[name] = value;
    } else if (path == "databaseInfo") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.database.postgres.databaseInfo[
        name
      ] = value;
    } else {
      var updatedSource = { ...newSource };
      updatedSource[name] = value;
      if (value == "database") {
        updatedSource.source_details.sourceMetadata["source"] = "postgres";
      }
      setMetaDataRequired("");
    }
    setSourceDetailsRequired("");
    setNewSource(updatedSource);
  };

  const getTemplateData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/metadata/template_source/allsources/`
      );
      let data = response?.data;
      console.log(data);
      setTemplateData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (isFirstRender.current && props?.ele) {
      setNewSource(props?.ele);
      setCronValue(props?.ele?.schedule);
      isFirstRender.current = false;
    }
    updateTimestamps();
  }, [props]);

  useEffect(() => {
    setCronValue(newSource?.schedule);
  }, [selectedTemplate]);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!isFirstRun.current) {
      setNewSource((prevSource) => {
        if (prevSource && newSource) {
          return {
            ...prevSource,
            schedule: cronValue,
          };
        }
        return { ...newSource, schedule: cronValue };
      });
    }
    
  }, [cronValue, newSource]);

  useEffect(() => {
    if (newSource) {
      let data = newSource?.source_schema?.constraints?.primarykeys || [];
  
      if (data[0]) {
        const result = Object.values(data[0]).map((item) => ({
          label: item,
          value: item,
        }));
        setPartitionKey(result);
      }
  
      if (data[1]) {
        const result2 = Object.values(data[1]).map((item) => ({
          label: item,
          value: item,
        }));
        setClusteringKey(result2);
      }
    }
    
  }, [newSource?.entity_name]);

  useEffect(() => {
    if (isFirstRender.current) {
      setNewSource(initColumn);
      isFirstRender.current = false;
    }
  }, [initColumn]);

  const updateTimestamps = async () => {
    setNewSource((source) => ({
      ...source,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
    }));
  };

  const handleSelectChange = (event) => {
    const selectedDataName = event.target.value;
    setSelectedTemplate(selectedDataName);
    const selectedTemplateData = templateData.find(
      (template) => template.data_name === selectedDataName
    );
    if (selectedTemplateData) {
      setNewSource(selectedTemplateData);
    }
  };

  const createNewSource = async () => {
    let isValid = false;
    let errorMessage = "Please fill all required fields";
    setNewSource((source) => ({
      ...source,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      schedule: cronValue,
    }));

    newSource?.source_schema?.schema?.fields.forEach((ele) => {
      if (
        (ele?.name && ele?.type && ele?.nullable !== undefined) ||
        newSource?.source_details?.sourceMetadata?.cloud?.azure?.controlFile
          ?.fileType == "pdf" ||
        newSource?.source_details?.sourceMetadata?.cloud?.s3?.dataFile
          ?.fileType == "pdf"
      ) {
        isValid = true;
      }
    });

    if (isValid) {
      setSourceLoader(true);
      try {
        const response = await axios.post(
          `${apiUrl}/metadata/source/`,
          newSource
        );
        let data = response?.data;
        setSourceLoader(false);
        setTog(0);
        toast.success(data?.message);
        getSourceData();
        getTemplateData();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setSourceLoader(false);
        toast.error(error?.response?.data?.error);
      }
    } else {
      setSchemaRequired(errorMessage);
    }

    return isValid;
  };

  const UpdateSource = async () => {
    try {
      const { edit, ...sourceWithoutEdit } = newSource;
      setNewSource((source) => ({
        ...source,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        schedule: cronValue,
      }));
      const response = await axios.put(
        `${apiUrl}/metadata/source/`,
        sourceWithoutEdit
      );
      let data = response?.data;
      getSourceData();
      getTemplateData();
      setTog(0);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      toast.success(data?.message);
      navigate("/ETL");
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getTemplateData();
    getSourceData();
  }, []);

  const handleNavigate = () => {
    navigate("/ETL");
  };

  useEffect(() => {
    if (newSource?.edit === true) {
      if (newSource?.source_schema == null) {
        newSource.source_schema = {
          schema: {
            fields: [
              {
                name: "",
                type: "text",
                nullable: true,
              },
            ],
          },
        };
      } else if (newSource.source_schema.schema == null) {
        newSource.source_schema.schema = {
          fields: [
            {
              name: "",
              type: "text",
              nullable: true,
            },
          ],
        };
      } else if (newSource.source_schema.schema.fields == null) {
        newSource.source_schema.schema.fields = [
          {
            name: "",
            type: "text",
            nullable: true,
          },
        ];
      }
    }
  }, [newSource?.edit]);

  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff", borderRadius: "12px" }}>
      <Box sx={{ borderColor: "divider" }}>
        {sourceLoader ? (
          <div
            className="position-relative"
            style={{ paddingTop: "15rem", paddingBottom: "2rem" }}
          >
            <div className="backdrop"></div>
            <div className="spinner-container">
              <Loader />
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="ms-4 mt-4">
            {newSource?.view ? "View Source" : "Create Source"}
          </h5>
          <button
            className="me-4 form-control pt-1 pb-1 d-flex align-items-center"
            style={{
              width: "fit-content",
              background: "none",
              color: "#1e88e5",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              setTog(0);
              navigate("/ETL");
            }}
          >
            <i className="fas fa-arrow-left me-2" />{" "}
          </button>
        </div>
        <Tabs
          value={value}
          className="ms-4"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Basic Details"
            {...a11yProps(0)}
            style={{ textTransform: "capitalize" }}
          />
          <Tab
            label="Source Details"
            {...a11yProps(1)}
            style={{ textTransform: "capitalize" }}
          />
          {/* <Tab label="Target Details" {...a11yProps(2)} /> */}
          <Tab
            label="Schema"
            {...a11yProps(2)}
            style={{
              textTransform: "capitalize",
              display:
                newSource?.source_details?.sourceMetadata?.cloud?.s3?.dataFile
                  ?.fileType === "pdf" ||
                newSource?.source_details?.sourceMetadata?.cloud?.azure
                  ?.dataFile?.fileType === "pdf"
                  ? "none"
                  : "",
            }}
          />
          <Tab label="Primary Key Details" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Card className="p-4" style={{ border: "none" }}>
          <div>
            <div>
              <div className="row">
                {!props?.ele ? (
                  <div className={isMobile ? "col-12" : "col-4"}>
                    <label>Select Template</label>
                    <select
                      className="form-control"
                      name="template"
                      onChange={handleSelectChange}
                      value={selectedTemplate}
                    >
                      <option value="">Select template</option>
                      {templateData.map((ele) => (
                        <option key={ele?.data_name} value={ele?.data_name}>
                          {ele?.data_name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className={isMobile ? "col-12" : "col-4"}>
                <label>Group Name</label>
                <span className="text-danger ms-1">*</span>
                <input
                  type="text"
                  className="form-control"
                  name="data_name"
                  disabled={newSource?.edit || newSource?.view}
                  value={newSource?.data_name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  onBlur={(e) => {
                    compareDataName(e);
                  }}
                />
              </div>
              <div className={isMobile ? "col-12" : "col-4"}>
                <label>Entity Name</label>
                <span className="text-danger ms-1">*</span>
                <input
                  type="text"
                  className="form-control"
                  name="entity_name"
                  disabled={newSource?.edit || newSource?.view}
                  value={newSource?.entity_name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
              <div className={isMobile ? "col-12" : "col-4"}>
                <label>Source Type</label>
                <span className="text-danger ms-1">*</span>
                <select
                  className="form-control"
                  name="source_type"
                  value={newSource?.source_type}
                  disabled={newSource?.view}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                >
                  <option value="">Select source type</option>
                  <option value="cloud">File</option>
                  <option value="streaming">Streaming</option>
                  <option value="database">Database</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label>Data Volume Type</label>
                <span className="text-danger ms-1">*</span>
                <select
                  className="form-control"
                  name="load_type"
                  value={newSource?.load_type}
                  disabled={newSource?.view}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                >
                  <option selected="" value="">
                    Select Data volume type
                  </option>
                  <option value="directLoad">Low-Volume Data</option>
                  <option value="hdfsLoad">High-Volume Data</option>
                </select>
              </div>
              <div className="col-6">
                {/* <div className={isMobile ? "col-12" : "col-4"}>
                  <label>Group Description</label>
                  <span className="text-danger ms-1">*</span>
                  <textarea
                    type="text"
                    className="form-control"
                    name="group_description"
                    disabled={match}
                    value={newSource?.group_description}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </div> */}
                <div className={isMobile ? "col-12 mt-3" : "col-6"}>
                  <label>Schedule</label>
                  <CronTab
                    cronTabValue={cronValue}
                    setCronTabValue={setCronValue}
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex mt-3">
              <div className="d-flex justify-content-end">
                {metaDataRequired !== "" ? (
                  <p className="p-0 text-danger me-4">{metaDataRequired}</p>
                ) : null}
                <div>
                  <Button
                    style={{
                      color: "white",
                      background: "#1e88e5",
                      border: "none",
                    }}
                    className="pt-1 pb-1 me-2"
                    onClick={handleNextTab}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Card className="p-4" style={{ border: "none" }}>
          {newSource?.source_type === "database" ? (
            <>
              <Box className={`mt-1 ${isMobile ? "text-center" : ""}`}>
                <p className="fw-bold">
                  Database Services<span className="text-danger ms-1">*</span>:{" "}
                </p>
              </Box>
              <Box className={`mt-1 ${isMobile ? "text-center" : ""}`}>
                <select
                  className="form-control"
                  name="source"
                  disabled={newSource?.view}
                  value={
                    newSource?.source_details?.sourceMetadata?.source ===
                    "postgres"
                      ? "OracleDb"
                      : newSource?.source_details?.sourceMetadata?.source
                  }
                  onChange={(e) => handleInputChange(e, "sourceMetadata")}
                >
                  <option value="oracleDb">OracleDB</option>
                  <option value="mongoDb" disabled>
                    MongoDB
                  </option>
                  <option value="postgres" disabled>
                    postgres
                  </option>
                </select>
              </Box>
              <Box className={`mt-1 ${isMobile ? "text-center" : ""}`}>
                <p className="fw-bold pb-0 mt-3">Database Info</p>
              </Box>
              <Box className={`row ${isMobile ? "flex-column" : ""}`}>
                <Box className={`col-${isMobile ? "12 mb-2" : "4"}`}>
                  <label>
                    Database Name<span className="text-danger ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="databaseName"
                    disabled={newSource?.view}
                    value={
                      newSource?.source_details?.sourceMetadata?.database
                        ?.postgres?.databaseInfo?.databaseName
                    }
                    onChange={(e) => handleInputChange(e, "databaseInfo")}
                  />
                </Box>
                <Box className={`col-${isMobile ? "12 mb-2" : "4"}`}>
                  <label>
                    Schema Name<span className="text-danger ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="schemaName"
                    disabled={newSource?.view}
                    value={
                      newSource?.source_details?.sourceMetadata?.database
                        ?.postgres?.databaseInfo?.schemaName
                    }
                    onChange={(e) => handleInputChange(e, "databaseInfo")}
                  />
                </Box>
              </Box>
            </>
          ) : newSource?.source_type === "streaming" ? (
            <>
              <Box className={`row mt-1 ${isMobile ? "flex-column" : ""}`}>
                <Box className={`col-${isMobile ? "12 mb-2" : "4"}`}>
                  <label>
                    Bootstrap Servers<span className="text-danger ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="bootstrapServers"
                    disabled={newSource?.view}
                    value={
                      newSource?.source_details?.sourceMetadata?.streaming
                        ?.kafka?.bootstrapServers
                    }
                    onChange={(e) => handleInputChange(e, "kafka")}
                  />
                </Box>
                <Box className={`col-${isMobile ? "12 mb-2" : "4"}`}>
                  <label>
                    Source Topic<span className="text-danger ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sourceTopic"
                    disabled={newSource?.view}
                    value={
                      newSource?.source_details?.sourceMetadata?.streaming
                        ?.kafka?.sourceTopic
                    }
                    onChange={(e) => handleInputChange(e, "kafka")}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "auto",
                      mb: isMobile ? 1 : 0,
                      ms: isMobile ? 0 : 2,
                    }}
                  >
                    <p className="fw-bold">
                      Cloud Services<span className="text-danger ms-1">*</span>{" "}
                      :
                    </p>
                  </Box>
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "25%",
                      marginLeft: isMobile ? "" : "5%",
                    }}
                  >
                    <select
                      className="form-control"
                      name="source"
                      disabled={newSource?.view}
                      value={
                        newSource?.source_details?.sourceMetadata?.source || ""
                      }
                      onChange={(e) => handleInputChange(e, "sourceMetadata")}
                    >
                      <option value="" disabled>
                        Select service
                      </option>
                      <option value="s3">S3</option>
                      <option value="azure">Azure</option>
                    </select>
                  </Box>
                </Box>
                <div className="mt-1">
                  <p className="fw-bold">Path Details</p>
                </div>
                <div className="row">
                  {newSource?.source_details?.sourceMetadata?.source ==
                  "azure" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          gap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Blob Container
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="blobContainer"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.path?.blobContainer || ""
                            }
                            onChange={(e) => handleInputChange(e, "azurePath")}
                          />
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Folder
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="blobPath"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.path?.blobPath || ""
                            }
                            onChange={(e) => handleInputChange(e, "azurePath")}
                          />
                        </div>
                      </div>

                      <div style={{ marginTop: "1rem" }}>
                        <p style={{ fontWeight: "bold" }}>Data Files Details</p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          gap: "1rem",
                          marginTop: isMobile ? "" : "1%",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Header
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="header"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.header
                            }
                            onChange={(e) =>
                              handleInputChange(e, "dataFileAzure")
                            }
                          >
                            <option value="" disabled>
                              Select Header
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            File Type
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="fileType"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.fileType
                            }
                            onChange={(e) =>
                              handleInputChange(e, "dataFileAzure")
                            }
                          >
                            <option value="" disabled>
                              Select File Type
                            </option>
                            <option value="csv">CSV</option>
                            <option value="txt">TXT</option>
                            <option value="pdf">PDF</option>
                            <option value="zip">ZIP</option>
                            <option value="gz">GZ</option>
                          </select>
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Delimiter
                            <span
                              className="ms-2"
                              style={{
                                color:
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.dataFile?.fileType == "pdf"
                                    ? "transparent"
                                    : "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="delimiter"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.delimiter
                            }
                            disabled={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.controlFile?.fileType == "pdf" ||
                              newSource?.view
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          gap: "1rem",
                          marginTop: isMobile ? "" : "1%",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>Files to Exclude</label>
                          <input
                            type="text"
                            className="form-control"
                            name="excludeFileNameAffix"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.excludeFileNameAffix
                            }
                            disabled={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.controlFile?.fileType == "pdf" ||
                              newSource?.view
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          />
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Table Order
                            <span
                              style={{
                                color:
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.dataFile?.fileType == "pdf"
                                    ? "transparent"
                                    : "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="tableOrder"
                            disabled={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.controlFile?.fileType == "pdf" ||
                              newSource?.view
                            }
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.tableOrder
                            }
                            onChange={(e) =>
                              handleInputChange(e, "sourceMetadata")
                            }
                          />
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Schema Type
                            <span
                              style={{
                                color:
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.dataFile?.fileType == "pdf"
                                    ? "transparent"
                                    : "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="schemaType"
                            disabled={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.controlFile?.fileType == "pdf" ||
                              newSource?.view
                            }
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.schemaType
                            }
                            onChange={(e) => handleInputChange(e, "azure")}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          gap: "1rem",
                          marginTop: isMobile ? "" : "1%",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Filename Pattern
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="fileNameAffix"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.fileNameAffix
                            }
                            onChange={(e) =>
                              handleInputChange(e, "dataFileAzure")
                            }
                          />
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: 0,
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          <label>
                            Has Control File
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="hasControlFile"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.hasControlFile
                            }
                            onChange={(e) =>
                              handleInputChange(e, "sourceMetadata")
                            }
                          >
                            <option value="" disabled>
                              Select Control File Details
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      {newSource?.source_details?.sourceMetadata
                        ?.hasControlFile == "true" && (
                        <>
                          <div className="mt-2">
                            <p className="fw-bold">Control Files Details</p>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: isMobile
                                ? "1fr"
                                : "repeat(3, 1fr)",
                              gap: "1rem",
                            }}
                            className="mt-1"
                          >
                            <div>
                              <label>File Name Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                name="fileNameAffix"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.fileNameAffix
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFileAzure")
                                }
                              />
                            </div>
                            <div>
                              <label>Affix Type</label>
                              <select
                                className="form-control"
                                name="affixType"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.affixType
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFileAzure")
                                }
                              >
                                <option value="">Select Affix type</option>
                                <option value="prefix">Prefix</option>
                                <option value="suffix">Suffix</option>
                              </select>
                            </div>
                            <div>
                              <label>Archived File Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                name="archivedFileNameAffix"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile
                                    ?.archivedFileNameAffix
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFileAzure")
                                }
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: isMobile
                                ? "1fr"
                                : "repeat(3, 1fr)",
                              gap: "1rem",
                            }}
                            className="mt-1"
                          >
                            <div>
                              <label>Header</label>
                              <select
                                className="form-control"
                                name="header"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.header
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFileAzure")
                                }
                              >
                                <option value="">Select Header</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <label>File Type</label>
                              <select
                                className="form-control"
                                name="fileType"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.fileType
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFileAzure")
                                }
                              >
                                <option value="">Select File type</option>
                                <option value="csv">CSV</option>
                                <option value="txt">TXT</option>
                                <option value="pdf">PDF</option>
                                <option value="zip">ZIP</option>
                                <option value="gz">GZ</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <label>
                                Delimiter
                                <span
                                  className="ms-2"
                                  style={{
                                    color:
                                      newSource?.source_details?.sourceMetadata
                                        ?.cloud?.azure?.controlFile?.fileType ==
                                      "pdf"
                                        ? "transparent"
                                        : "red",
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="delimiter"
                                disabled={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.fileType ==
                                    "pdf" || newSource?.view
                                }
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.delimiter
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: isMobile
                                ? "1fr"
                                : "repeat(3, 1fr)",
                              gap: "1rem",
                            }}
                            className="mt-1"
                          >
                            <div>
                              <label>Files to Exclude</label>
                              <input
                                type="text"
                                className="form-control"
                                name="excludeFileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile
                                    ?.excludeFileNameAffix
                                }
                                disabled={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.fileType ==
                                    "pdf" || newSource?.view
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <p className="fw-bold">Connection Details</p>
                          </div>

                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: isMobile
                                ? "1fr"
                                : "repeat(3, 1fr)",
                              gap: "1rem",
                            }}
                          >
                            <div>
                              <label>S3 URI</label>
                              <input
                                type="text"
                                className="form-control"
                                name="s3URI"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.connectionDetails?.s3URI
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "connectionAzure")
                                }
                              />
                            </div>
                            <div>
                              <label>Auth Type</label>
                              <input
                                type="text"
                                className="form-control"
                                name="authType"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.connectionDetails?.authType
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "connectionAzure")
                                }
                              />
                            </div>
                            <div>
                              <label>Region</label>
                              <input
                                type="text"
                                className="form-control"
                                name="region"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.connectionDetails?.region
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "connectionAzure")
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: isMobile ? "100%" : "30%",
                          }}
                        >
                          <label>
                            S3 Bucket
                            <span
                              style={{ color: "red", marginLeft: "0.5rem" }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="s3Bucket"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.path?.s3Bucket
                            }
                            onChange={(e) => handleInputChange(e, "s3Path")}
                          />
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: isMobile ? "100%" : "30%",
                          }}
                        >
                          <label>
                            Folder
                            <span
                              style={{ color: "red", marginLeft: "0.5rem" }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="folder"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.path?.folder
                            }
                            onChange={(e) => handleInputChange(e, "s3Path")}
                          />
                        </div>
                      </div>

                      <div style={{ marginTop: "1rem", fontWeight: "bold" }}>
                        Data Files Details
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: isMobile ? "100%" : "30%",
                          }}
                        >
                          <label>
                            Header
                            <span
                              style={{ color: "red", marginLeft: "0.5rem" }}
                            >
                              *
                            </span>
                          </label>
                          <select
                            className="form-control"
                            name="header"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.header
                            }
                            onChange={(e) => handleInputChange(e, "dataFile")}
                          >
                            <option value="">Select Header</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: isMobile ? "100%" : "30%",
                          }}
                        >
                          <label>
                            File Type
                            <span
                              style={{ color: "red", marginLeft: "0.5rem" }}
                            >
                              *
                            </span>
                          </label>
                          <select
                            className="form-control"
                            name="fileType"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.fileType
                            }
                            onChange={(e) => handleInputChange(e, "dataFile")}
                          >
                            <option value="">Select File Type</option>
                            <option value="csv">CSV</option>
                            <option value="txt">TXT</option>
                            <option value="pdf">PDF</option>
                            <option value="zip">ZIP</option>
                            <option value="gz">GZ</option>
                          </select>
                        </div>
                        {newSource?.source_details?.sourceMetadata?.cloud?.s3
                          ?.dataFile?.fileType !== "pdf" ? (
                          <div
                            style={{
                              flex: "1 1 0",
                              minWidth: isMobile ? "100%" : "30%",
                            }}
                          >
                            <label>
                              Delimiter
                              <span
                                style={{
                                  color:
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.dataFile?.fileType == "pdf"
                                      ? "transparent"
                                      : "red",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="delimiter"
                              value={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.dataFile?.delimiter
                              }
                              disabled={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.dataFile?.fileType == "pdf" ||
                                newSource?.view
                              }
                              onChange={(e) => {
                                handleInputChange(e, "dataFile");
                              }}
                            />
                          </div>
                        ) : null}
                      </div>

                      {newSource?.source_details?.sourceMetadata?.cloud?.s3
                        ?.dataFile?.fileType !== "pdf" ? (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "1rem",
                            marginTop: "1rem",
                          }}
                        >
                          <div
                            style={{
                              flex: "1 1 0",
                              minWidth: isMobile ? "100%" : "30%",
                            }}
                          >
                            <label>Files to Exclude</label>
                            <input
                              type="text"
                              className="form-control"
                              name="excludeFileNameAffix"
                              value={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.dataFile?.excludeFileNameAffix
                              }
                              disabled={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.dataFile?.fileType == "pdf" ||
                                newSource?.view
                              }
                              onChange={(e) => {
                                handleInputChange(e, "dataFile");
                              }}
                            />
                          </div>
                          <div
                            style={{
                              flex: "1 1 0",
                              minWidth: isMobile ? "100%" : "30%",
                            }}
                          >
                            <label>
                              Table Order
                              <span
                                className="ms-1"
                                style={{
                                  color:
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.dataFile?.fileType == "pdf"
                                      ? "transparent"
                                      : "red",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="tableOrder"
                              value={
                                newSource?.source_details?.sourceMetadata
                                  ?.tableOrder
                              }
                              disabled={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.dataFile?.fileType == "pdf" ||
                                newSource?.view
                              }
                              onChange={(e) => {
                                handleInputChange(e, "sourceMetadata");
                              }}
                            />
                          </div>
                          <div
                            style={{
                              flex: "1 1 0",
                              minWidth: isMobile ? "100%" : "30%",
                            }}
                          >
                            <label>
                              Schema Type
                              <span
                                className="ms-1"
                                style={{
                                  color:
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.dataFile?.fileType == "pdf"
                                      ? "transparent"
                                      : "red",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="schemaType"
                              value={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.schemaType
                              }
                              disabled={
                                newSource?.source_details?.sourceMetadata?.cloud
                                  ?.s3?.dataFile?.fileType == "pdf" ||
                                newSource?.view
                              }
                              onChange={(e) => {
                                handleInputChange(e, "s3");
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: isMobile ? "100%" : "30%",
                          }}
                        >
                          <label>
                            Filename Pattern
                            <span
                              style={{ color: "red", marginLeft: "0.5rem" }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="fileNameAffix"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.fileNameAffix
                            }
                            onChange={(e) => handleInputChange(e, "dataFile")}
                          />
                        </div>
                        <div
                          style={{
                            flex: "1 1 0",
                            minWidth: isMobile ? "100%" : "30%",
                          }}
                        >
                          <label>
                            Has Control File
                            <span
                              style={{ color: "red", marginLeft: "0.5rem" }}
                            >
                              *
                            </span>
                          </label>
                          <select
                            className="form-control"
                            name="hasControlFile"
                            disabled={newSource?.view}
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.hasControlFile
                            }
                            onChange={(e) =>
                              handleInputChange(e, "sourceMetadata")
                            }
                          >
                            <option value="">
                              Select Control File Details
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      {newSource?.source_details?.sourceMetadata
                        ?.hasControlFile == "true" && (
                        <>
                          <div style={{ marginTop: "1rem" }}>
                            <p style={{ fontWeight: "bold" }}>
                              Control Files Details
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isMobile ? "column" : "row",
                              gap: "1rem",
                            }}
                          >
                            <div style={{ flex: "1 1 0", minWidth: 0 }}>
                              <label>File Name Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "100%" }}
                                name="fileNameAffix"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.fileNameAffix
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFile")
                                }
                              />
                            </div>
                            <div style={{ flex: "1 1 0", minWidth: 0 }}>
                              <label>Affix Type</label>
                              <select
                                className="form-control"
                                style={{ width: "100%" }}
                                name="affixType"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.affixType
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFile")
                                }
                              >
                                <option value="">Select Affix type</option>
                                <option value="prefix">Prefix</option>
                                <option value="suffix">Suffix</option>
                              </select>
                            </div>
                            <div style={{ flex: "1 1 0", minWidth: 0 }}>
                              <label>Archived File Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "100%" }}
                                name="archivedFileNameAffix"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile
                                    ?.archivedFileNameAffix
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "controlFile")
                                }
                              />
                            </div>
                          </div>

                          <div style={{ marginTop: "1rem" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: isMobile ? "column" : "row",
                                gap: "1rem",
                              }}
                            >
                              <div style={{ flex: "1 1 0", minWidth: 0 }}>
                                <label>Header</label>
                                <select
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  name="header"
                                  disabled={newSource?.view}
                                  value={
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.controlFile?.header
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "controlFile")
                                  }
                                >
                                  <option value="">Select Header</option>
                                  <option value="true">True</option>
                                  <option value="false">False</option>
                                </select>
                              </div>
                              <div style={{ flex: "1 1 0", minWidth: 0 }}>
                                <label>File Type</label>
                                <select
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  name="fileType"
                                  disabled={newSource?.view}
                                  value={
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.controlFile?.fileType
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "controlFile")
                                  }
                                >
                                  <option value="">Select File type</option>
                                  <option value="csv">CSV</option>
                                  <option value="txt">TXT</option>
                                  <option value="zip">ZIP</option>
                                  <option value="gz">GZ</option>
                                </select>
                              </div>
                              <div style={{ flex: "1 1 0", minWidth: 0 }}>
                                <label>
                                  Delimiter
                                  <span
                                    className="ms-2"
                                    style={{
                                      color:
                                        newSource?.source_details
                                          ?.sourceMetadata?.cloud?.s3
                                          ?.controlFile?.fileType == "pdf"
                                          ? "transparent"
                                          : "red",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  name="delimiter"
                                  disabled={newSource?.view}
                                  value={
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.controlFile?.delimiter
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "controlFile")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: "1rem" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: isMobile ? "column" : "row",
                                gap: "1rem",
                              }}
                            >
                              <div style={{ flex: "1 1 0", minWidth: 0 }}>
                                <label>Files to Exclude</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  name="excludeFileNameAffix"
                                  disabled={newSource?.view}
                                  value={
                                    newSource?.source_details?.sourceMetadata
                                      ?.cloud?.s3?.controlFile
                                      ?.excludeFileNameAffix
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "controlFile")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: "1rem" }}>
                            <p style={{ fontWeight: "bold" }}>
                              Connection Details
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isMobile ? "column" : "row",
                              gap: "1rem",
                            }}
                          >
                            <div style={{ flex: "1 1 0", minWidth: 0 }}>
                              <label>S3 URI</label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "100%" }}
                                name="s3URI"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.connectionDetails?.s3URI
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "connection")
                                }
                              />
                            </div>
                            <div style={{ flex: "1 1 0", minWidth: 0 }}>
                              <label>Auth Type</label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "100%" }}
                                name="authType"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.connectionDetails?.authType
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "connection")
                                }
                              />
                            </div>
                            <div style={{ flex: "1 1 0", minWidth: 0 }}>
                              <label>Region</label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "100%" }}
                                name="region"
                                disabled={newSource?.view}
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.connectionDetails?.region
                                }
                                onChange={(e) =>
                                  handleInputChange(e, "connection")
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="row d-flex mt-2">
            <div className="d-flex justify-content-end">
              {sourceDetailsRequired != "" ? (
                <p className="p-0 text-danger me-4">{sourceDetailsRequired}</p>
              ) : null}
              <div>
                {(newSource?.view &&
                  newSource?.source_details?.sourceMetadata?.cloud?.azure
                    ?.dataFile?.fileType == "pdf") ||
                newSource?.source_details?.sourceMetadata?.cloud?.s3?.dataFile
                  ?.fileType == "pdf" ? (
                  <Button
                    style={{
                      color: "white",
                      background: "#1e88e5",
                      border: "none",
                    }}
                    className="pt-1 pb-1 me-2"
                    onClick={() => {
                      setTog(0);
                      navigate("/ETL");
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: "white",
                      background: "#1e88e5",
                      border: "none",
                    }}
                    className="pt-1 pb-1 me-2"
                    onClick={
                      newSource?.source_details?.sourceMetadata?.cloud?.azure
                        ?.dataFile?.fileType == "pdf" ||
                      newSource?.source_details?.sourceMetadata?.cloud?.s3
                        ?.dataFile?.fileType == "pdf"
                        ? createNewSource
                        : handleNextTab
                    }
                  >
                    {newSource?.source_details?.sourceMetadata?.cloud?.azure
                      ?.dataFile?.fileType == "pdf" ||
                    newSource?.source_details?.sourceMetadata?.cloud?.s3
                      ?.dataFile?.fileType == "pdf"
                      ? "Save"
                      : "Next"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Card>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        <Card className="p-4">
          <div className="row d-flex">
            <div className="d-flex justify-content-end">
              <Button
                style={{
                  color: "white",
                  background: "#1e88e5",
                  border: "none",
                }}
                className="pt-1 pb-1 me-2"
                onClick={handleNextTab}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={2}>
        <Card className="p-4" style={{ border: "none" }}>
          <div className="mt-2">
            <p className="fw-bold">Source Schema</p>
          </div>
          {newSource?.source_schema?.schema?.fields.map((ele, index) => (
            <div
              className={`row mt-1 ${isMobile ? "flex-column" : "flex-row"}`}
              key={index}
            >
              <div
                className={`col-${isMobile ? "12" : "4"} ${
                  isMobile ? "mb-2" : ""
                }`}
              >
                {index === 0 && (
                  <label
                    className={`text-center ${
                      isMobile ? "w-100" : "text-start"
                    } fw-bold mb-2 pe-2`}
                  >
                    Column Name<span className="text-danger ms-1">*</span>
                  </label>
                )}
                <input
                  type="text"
                  className="form-control"
                  name={`source_schema.schema.fields[${index}].name`}
                  value={ele?.name || ""}
                  disabled={
                    newSource?.source_details?.sourceMetadata?.cloud?.azure
                      ?.controlFile?.fileType == "pdf" ||
                    newSource?.source_details?.sourceMetadata?.cloud?.s3
                      ?.dataFile?.fileType == "pdf" ||
                    newSource?.view
                  }
                  onChange={(e) => {
                    handleFieldChange(e, index, "name");
                  }}
                />
              </div>
              <div
                className={`col-${isMobile ? "12" : "4"} ${
                  isMobile ? "mb-2" : ""
                }`}
              >
                {index === 0 && (
                  <label
                    className={`text-center ${
                      isMobile ? "w-100" : "text-start"
                    } fw-bold mb-2 pe-2`}
                  >
                    Data Type<span className="text-danger ms-1">*</span>
                  </label>
                )}
                <select
                  className="form-control"
                  name={`source_schema.schema.fields[${index}].type`}
                  value={ele?.type || ""}
                  disabled={
                    newSource?.source_details?.sourceMetadata?.cloud?.azure
                      ?.controlFile?.fileType == "pdf" ||
                    newSource?.source_details?.sourceMetadata?.cloud?.s3
                      ?.dataFile?.fileType == "pdf" ||
                    newSource?.view
                  }
                  onChange={(e) => handleFieldChange(e, index, "type")}
                >
                  <option value="">Select Data type</option>
                  <option value="integer">INTEGER</option>
                  <option value="float">FLOAT</option>
                  <option value="long">LONG</option>
                  <option value="text">TEXT</option>
                  <option value="boolean">BOOLEAN</option>
                  <option value="string">STRING</option>
                  <option value="timestamp">TIMESTAMP</option>
                </select>
              </div>
              <div
                className={`col-${isMobile ? "12" : "3"} ${
                  isMobile ? "mb-2" : ""
                }`}
              >
                {index === 0 && (
                  <label
                    className={`text-center ${
                      isMobile ? "w-100" : "text-start"
                    } fw-bold mb-2 pe-2`}
                  >
                    Nullable<span className="text-danger ms-1">*</span>
                  </label>
                )}
                <select
                  className="form-control"
                  name={`nullable_${index}`}
                  value={
                    ele?.nullable === true
                      ? "true"
                      : ele?.nullable === false
                      ? "false"
                      : ""
                  }
                  disabled={
                    newSource?.source_details?.sourceMetadata?.cloud?.azure
                      ?.controlFile?.fileType == "pdf" ||
                    newSource?.source_details?.sourceMetadata?.cloud?.s3
                      ?.dataFile?.fileType == "pdf" ||
                    newSource?.view
                  }
                  onChange={(e) => handleFieldChange(e, index, "nullable")}
                >
                  <option value="">Select Nullable</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div
                className={`col-${
                  isMobile ? "12" : "1"
                } d-flex align-items-end`}
              >
                {index === newSource.source_schema.schema.fields.length - 1 ? (
                  <>
                    {index !== 0 && (
                      <Tooltip title="Remove" placement="top">
                        <button
                          type="button"
                          className="btn text-danger"
                          style={{ display: newSource?.view ? "none" : "" }}
                          onClick={() => removeRow(index)}
                        >
                          <b>
                            <RemoveIcon />
                          </b>
                        </button>
                      </Tooltip>
                    )}

                    <Tooltip title="Add New" placement="top">
                      <button
                        type="button"
                        className="btn text-primary"
                        style={{ display: newSource?.view ? "none" : "" }}
                        onClick={addRow}
                      >
                        <b>+</b>
                      </button>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Remove" placement="top">
                    <button
                      type="button"
                      className="btn text-danger"
                      onClick={() => removeRow(index)}
                      style={{ display: newSource?.view ? "none" : "" }}
                    >
                      <b>
                        <RemoveIcon />
                      </b>
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          ))}

          <div className="row d-flex mt-3">
            <div className="d-flex justify-content-end">
              {schemaRequired && (
                <p className="p-0 text-danger me-4">{schemaRequired}</p>
              )}
              <Button
                style={{
                  color: "white",
                  background: "#1e88e5",
                  border: "none",
                }}
                className="pt-1 pb-1"
                onClick={handleNextTab}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card className="p-4" style={{ border: "none" }}>
          <div className="">
            <p className="fw-bold">Primary Key Details</p>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={5}>
                <p>Partition Key</p>
                <Select
                  isMulti
                  options={partitionKeyOptions}
                  onChange={handlePartitionChange}
                  value={partitionKey}
                  placeholder="Select Partition Key"
                  controlShouldRenderValue={false}
                  isDisabled={newSource?.view}
                />
                <div className="mt-2">
                  {partitionKey.map((selected, index) => (
                    <Chip
                      key={index}
                      label={selected.label}
                      color="primary"
                      className="m-1"
                      onDelete={() => handlePartitionChipDelete(selected)}
                    />
                  ))}
                </div>
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mx: { xs: 0, sm: 2 },
                  display: { xs: "none", sm: "block" },
                }}
              />

              <Grid item xs={12} sm={5}>
                <p>Clustering Key</p>
                <Select
                  isMulti
                  options={clusteringKeyOptions}
                  onChange={handleClusteringChange}
                  value={clusteringKey}
                  placeholder="Select Clustering Key"
                  controlShouldRenderValue={false}
                  isDisabled={newSource?.view}
                />
                <div className="mt-2">
                  {clusteringKey.map((selected, index) => (
                    <Chip
                      key={index}
                      label={selected.label}
                      color="secondary"
                      className="m-1"
                      onDelete={() => handleClusteringChipDelete(selected)}
                    />
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="row d-flex mt-2">
            <div className="d-flex justify-content-end">
              <div>
                {newSource?.edit ? (
                  <Button
                    style={{
                      color: "white",
                      background: "#1e88e5",
                      border: "none",
                    }}
                    className="pt-1 pb-1"
                    onClick={() => {
                      UpdateSource();
                    }}
                  >
                    Update
                  </Button>
                ) : newSource?.view ? (
                  <Button
                    style={{
                      color: "white",
                      background: "#1e88e5",
                      border: "none",
                    }}
                    className="pt-1 pb-1 me-2"
                    onClick={() => {
                      setTog(0);
                      navigate("/ETL");
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: "white",
                      background: "#1e88e5",
                      border: "none",
                    }}
                    className="pt-1 pb-1"
                    onClick={() => {
                      createNewSource();
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Card>
      </CustomTabPanel>
    </Box>
  );
}

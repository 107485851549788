import React from 'react'

function RepoIcon() {
  return (
    <span style={{width:'22px',height:'24px'}}>
      <svg  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="100%" viewBox="0 0 321 320" enable-background="new 0 0 321 320">
<path fill="transparent" opacity="1.000000" stroke="none" 
	d="
M180.000000,321.000000 
	C120.000023,321.000000 60.500042,321.000000 1.000048,321.000000 
	C1.000032,214.333389 1.000032,107.666786 1.000016,1.000134 
	C107.999901,1.000089 214.999802,1.000089 321.999756,1.000045 
	C321.999847,107.666534 321.999847,214.333069 321.999939,320.999817 
	C274.833344,321.000000 227.666672,321.000000 180.000000,321.000000 
M217.171005,70.906937 
	C209.761063,68.060013 202.303009,65.326988 194.994751,62.239376 
	C193.937836,61.792847 193.190903,59.700581 192.994598,58.274353 
	C191.522446,47.579033 190.175339,36.865551 188.905426,26.143976 
	C188.571182,23.321922 187.430176,22.067762 184.436462,22.146643 
	C178.108734,22.313374 171.773514,22.196194 165.441376,22.195969 
	C157.485687,22.195688 149.529999,22.195902 141.292191,22.195902 
	C139.807007,33.918259 138.316711,45.089069 137.016937,56.282005 
	C136.599091,59.880196 135.596268,62.258423 131.583633,63.444813 
	C126.517281,64.942749 121.647881,67.261818 116.907791,69.651665 
	C113.945076,71.145393 111.808052,70.910820 109.258728,68.849701 
	C101.365868,62.468369 93.255798,56.356159 85.338417,50.004395 
	C82.712151,47.897453 80.865349,47.560726 78.234085,50.296162 
	C69.456230,59.421532 60.490120,68.373306 51.373966,77.161118 
	C48.719048,79.720413 48.730919,81.558868 50.931423,84.260567 
	C55.554222,89.936256 59.446865,96.293388 64.556938,101.463791 
	C71.117699,108.102013 71.257523,113.645035 67.933578,122.733200 
	C63.913948,133.723465 57.872738,136.890915 47.478230,137.241470 
	C39.449116,137.512253 31.469967,139.264496 23.609917,140.338776 
	C23.609917,148.478653 23.609917,156.042374 23.609917,163.649445 
	C55.126633,163.649445 86.228661,163.649445 117.275017,163.649445 
	C123.621796,133.001038 138.884644,117.450951 162.651047,116.912872 
	C173.696472,116.662804 183.714523,119.451836 192.640472,125.960716 
	C205.332413,135.215805 211.042542,148.236938 212.284485,163.627350 
	C243.571533,163.627350 274.479645,163.627350 305.694458,163.627350 
	C305.694458,155.779099 305.694458,148.214523 305.694458,140.169540 
	C294.351654,138.755356 283.191650,137.241135 271.998138,136.039627 
	C268.413818,135.654892 266.422760,134.388214 265.214355,130.794235 
	C263.420135,125.457916 261.025757,120.302505 258.590210,115.212021 
	C257.464600,112.859398 257.276245,111.295128 259.097931,109.041740 
	C266.621063,99.735878 273.858734,90.199234 281.236603,80.701927 
	C280.763184,79.962311 280.500916,79.325844 280.049744,78.881012 
	C270.086487,69.057617 260.162506,59.191975 250.015747,49.561260 
	C249.137970,48.728123 246.250687,48.785908 245.161530,49.594521 
	C235.941513,56.439590 226.922516,63.555412 217.171005,70.906937 
M72.259895,282.031311 
	C85.829834,280.534698 94.605659,270.654480 93.912292,257.056671 
	C93.705482,253.000793 94.761223,250.207947 97.723763,247.497726 
	C102.876472,242.783875 107.865944,237.852325 112.521301,232.651718 
	C114.640648,230.284134 117.053696,227.110016 117.176277,224.210541 
	C117.692337,212.004211 117.394493,199.763474 117.394493,187.416656 
	C109.668243,187.416656 102.111511,187.416656 94.083977,187.416656 
	C94.083977,193.865021 93.545731,200.046356 94.214355,206.094254 
	C95.139671,214.463913 92.642418,220.813416 85.943047,225.914383 
	C80.963150,229.706131 78.116272,235.123962 69.614639,234.702499 
	C56.051579,234.030121 45.330986,246.459167 46.184757,259.181305 
	C47.077335,272.481598 57.861820,283.171021 72.259895,282.031311 
M266.584106,235.704239 
	C264.974426,235.313248 263.200745,234.275406 261.781403,234.635208 
	C251.838684,237.155792 247.679413,229.203613 241.693375,224.196945 
	C236.856552,220.151474 235.276230,215.544067 235.691162,209.408249 
	C236.180603,202.170197 235.805984,194.873718 235.805984,187.415588 
	C227.613831,187.415588 220.064178,187.415588 211.998047,187.415588 
	C211.998047,198.848068 212.081314,209.959442 211.958344,221.068542 
	C211.906921,225.715286 213.516861,229.378616 216.853149,232.583984 
	C222.612885,238.117706 228.300171,243.739914 233.739975,249.583679 
	C234.990128,250.926605 235.920670,253.452484 235.628113,255.205292 
	C233.387680,268.629425 245.643143,286.414795 265.918884,281.351624 
	C275.512512,278.955933 282.637115,270.044647 283.081146,260.942657 
	C283.677124,248.726212 278.632355,240.707275 266.584106,235.704239 
M187.258301,274.603149 
	C184.784348,270.931702 182.224335,267.314270 179.872040,263.566467 
	C178.659637,261.634796 176.982330,259.487305 176.942184,257.409760 
	C176.678329,243.756973 176.813766,230.096634 176.789368,216.438797 
	C176.786240,214.693558 176.611694,212.948608 176.516373,211.199951 
	C168.442245,211.199951 161.014755,211.199951 153.261002,211.199951 
	C153.163589,213.090836 153.011108,214.710648 153.007889,216.330734 
	C152.986359,227.157059 152.948547,237.983734 153.014618,248.809692 
	C153.054626,255.362946 154.166870,262.209686 147.043106,266.315887 
	C146.506668,266.625092 146.174011,267.313812 145.789261,267.856323 
	C139.076935,277.321442 139.748703,288.410553 147.589111,297.687286 
	C153.858475,305.105164 165.510193,307.157166 175.037140,302.521240 
	C185.463562,297.447540 189.343277,289.036865 187.258301,274.603149 
z"/>
<path color="rgba(0, 0, 0, 0.54)"  opacity="1.000000" stroke="none" 
	d="
M217.507080,70.748444 
	C226.922516,63.555412 235.941513,56.439590 245.161530,49.594521 
	C246.250687,48.785908 249.137970,48.728123 250.015747,49.561260 
	C260.162506,59.191975 270.086487,69.057617 280.049744,78.881012 
	C280.500916,79.325844 280.763184,79.962311 281.236603,80.701927 
	C273.858734,90.199234 266.621063,99.735878 259.097931,109.041740 
	C257.276245,111.295128 257.464600,112.859398 258.590210,115.212021 
	C261.025757,120.302505 263.420135,125.457916 265.214355,130.794235 
	C266.422760,134.388214 268.413818,135.654892 271.998138,136.039627 
	C283.191650,137.241135 294.351654,138.755356 305.694458,140.169540 
	C305.694458,148.214523 305.694458,155.779099 305.694458,163.627350 
	C274.479645,163.627350 243.571533,163.627350 212.284485,163.627350 
	C211.042542,148.236938 205.332413,135.215805 192.640472,125.960716 
	C183.714523,119.451836 173.696472,116.662804 162.651047,116.912872 
	C138.884644,117.450951 123.621796,133.001038 117.275017,163.649445 
	C86.228661,163.649445 55.126633,163.649445 23.609917,163.649445 
	C23.609917,156.042374 23.609917,148.478653 23.609917,140.338776 
	C31.469967,139.264496 39.449116,137.512253 47.478230,137.241470 
	C57.872738,136.890915 63.913948,133.723465 67.933578,122.733200 
	C71.257523,113.645035 71.117699,108.102013 64.556938,101.463791 
	C59.446865,96.293388 55.554222,89.936256 50.931423,84.260567 
	C48.730919,81.558868 48.719048,79.720413 51.373966,77.161118 
	C60.490120,68.373306 69.456230,59.421532 78.234085,50.296162 
	C80.865349,47.560726 82.712151,47.897453 85.338417,50.004395 
	C93.255798,56.356159 101.365868,62.468369 109.258728,68.849701 
	C111.808052,70.910820 113.945076,71.145393 116.907791,69.651665 
	C121.647881,67.261818 126.517281,64.942749 131.583633,63.444813 
	C135.596268,62.258423 136.599091,59.880196 137.016937,56.282005 
	C138.316711,45.089069 139.807007,33.918259 141.292191,22.195902 
	C149.529999,22.195902 157.485687,22.195688 165.441376,22.195969 
	C171.773514,22.196194 178.108734,22.313374 184.436462,22.146643 
	C187.430176,22.067762 188.571182,23.321922 188.905426,26.143976 
	C190.175339,36.865551 191.522446,47.579033 192.994598,58.274353 
	C193.190903,59.700581 193.937836,61.792847 194.994751,62.239376 
	C202.303009,65.326988 209.761063,68.060013 217.507080,70.748444 
z"/>
<path color="rgba(0, 0, 0, 0.54)"  opacity="1.000000" stroke="none" 
	d="
M71.810814,282.051453 
	C57.861820,283.171021 47.077335,272.481598 46.184757,259.181305 
	C45.330986,246.459167 56.051579,234.030121 69.614639,234.702499 
	C78.116272,235.123962 80.963150,229.706131 85.943047,225.914383 
	C92.642418,220.813416 95.139671,214.463913 94.214355,206.094254 
	C93.545731,200.046356 94.083977,193.865021 94.083977,187.416656 
	C102.111511,187.416656 109.668243,187.416656 117.394493,187.416656 
	C117.394493,199.763474 117.692337,212.004211 117.176277,224.210541 
	C117.053696,227.110016 114.640648,230.284134 112.521301,232.651718 
	C107.865944,237.852325 102.876472,242.783875 97.723763,247.497726 
	C94.761223,250.207947 93.705482,253.000793 93.912292,257.056671 
	C94.605659,270.654480 85.829834,280.534698 71.810814,282.051453 
z"/>
<path color="rgba(0, 0, 0, 0.54)"  opacity="1.000000" stroke="none" 
	d="
M266.962036,235.812653 
	C278.632355,240.707275 283.677124,248.726212 283.081146,260.942657 
	C282.637115,270.044647 275.512512,278.955933 265.918884,281.351624 
	C245.643143,286.414795 233.387680,268.629425 235.628113,255.205292 
	C235.920670,253.452484 234.990128,250.926605 233.739975,249.583679 
	C228.300171,243.739914 222.612885,238.117706 216.853149,232.583984 
	C213.516861,229.378616 211.906921,225.715286 211.958344,221.068542 
	C212.081314,209.959442 211.998047,198.848068 211.998047,187.415588 
	C220.064178,187.415588 227.613831,187.415588 235.805984,187.415588 
	C235.805984,194.873718 236.180603,202.170197 235.691162,209.408249 
	C235.276230,215.544067 236.856552,220.151474 241.693375,224.196945 
	C247.679413,229.203613 251.838684,237.155792 261.781403,234.635208 
	C263.200745,234.275406 264.974426,235.313248 266.962036,235.812653 
z"/>
<path color="rgba(0, 0, 0, 0.54)"  opacity="1.000000" stroke="none" 
	d="
M187.406525,274.993896 
	C189.343277,289.036865 185.463562,297.447540 175.037140,302.521240 
	C165.510193,307.157166 153.858475,305.105164 147.589111,297.687286 
	C139.748703,288.410553 139.076935,277.321442 145.789261,267.856323 
	C146.174011,267.313812 146.506668,266.625092 147.043106,266.315887 
	C154.166870,262.209686 153.054626,255.362946 153.014618,248.809692 
	C152.948547,237.983734 152.986359,227.157059 153.007889,216.330734 
	C153.011108,214.710648 153.163589,213.090836 153.261002,211.199951 
	C161.014755,211.199951 168.442245,211.199951 176.516373,211.199951 
	C176.611694,212.948608 176.786240,214.693558 176.789368,216.438797 
	C176.813766,230.096634 176.678329,243.756973 176.942184,257.409760 
	C176.982330,259.487305 178.659637,261.634796 179.872040,263.566467 
	C182.224335,267.314270 184.784348,270.931702 187.406525,274.993896 
z"/>
</svg>
    </span>
  )
}

export default RepoIcon

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../assets/styles/style.css"

// third-party
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

// project imports
import MainCard from "../cards/MainCard";

const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];

const SkeletonTotalGrowthBarChart = () => (
  <Card>
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item xs zeroMinWidth>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={20} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" height={50} width={80} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={530} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const TotalSourceChart = ({ isLoading, chartDatas }) => {
  const [value, setValue] = useState("today");
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const primary = theme.palette.text.primary;
  const divider = theme.palette.divider;
  const grey500 = theme.palette.grey[500];

  const primary200 = theme.palette.primary[200];
  const primaryDark = theme.palette.primary.dark;
  const secondaryMain = theme.palette.secondary.main;
  const secondaryLight = theme.palette.secondary.light;

  const dates = Object.keys(chartDatas).sort();

  const allKeys = Array.from(
    new Set(dates.flatMap((date) => Object.keys(chartDatas[date])))
  );

  const series = allKeys.map((key) => ({
    name: key,
    data: dates.map((date) => chartDatas[date][key] || 0),
  }));

  const categories = dates;
  const chartData = {
    series: series,
    options: {
      chart: {
        id: "bar-chart",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      xaxis: {
        type: "category",
        categories: categories,
      },
      legend: {
        show: true,
        fontFamily: `'Roboto', sans-serif`,
        position: "bottom",
        offsetX: 20,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
      },
      fill: {
        type: "solid",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
    },
    type: "bar",
    height: 467,
  };

  useEffect(() => {
    const newChartData = {
      ...chartData.options,
      colors: [primary200, primaryDark, secondaryMain, secondaryLight],
      xaxis: {
        ...chartData.options.xaxis,
        labels: {
          style: {
            colors: Array(12).fill(primary), // Ensuring labels colors match the length of categories
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      grid: { borderColor: divider },
      tooltip: { theme: "light" },
      legend: { labels: { colors: grey500 } },
    };

    if (!isLoading) {
      ApexCharts.exec("bar-chart", "updateOptions", newChartData);
    }
  }, [
    primary200,
    primaryDark,
    secondaryMain,
    secondaryLight,
    primary,
    divider,
    isLoading,
    grey500,
  ]);

  const downloadPNG = () => {
    ApexCharts.exec("bar-chart", "dataURI").then((imgData) => {
      const a = document.createElement("a");
      a.href = imgData.imgURI;
      a.download = "chart.png";
      a.click();
    });
  };


  const downloadCSV = () => {
    const csvData = series.map((s) => ({
      name: s.name,
      data: s.data,
    }));
    
    const csvContent = [
      ["Category", ...csvData.map((s) => s.name)], 
      ...categories.map((category, i) => [
        category,
        ...csvData.map((s) => s.data[i]), 
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "chart.csv";
    a.click();
    URL.revokeObjectURL(url);
  };


  const downloadSVG = () => {
    ApexCharts.exec("bar-chart", "dataURI", { type: 'image/svg+xml' }).then((imgData) => {
      const a = document.createElement("a");
      a.href = imgData.svgURI;
      a.download = "chart.svg";
      a.click();
    });
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonTotalGrowthBarChart />
      ) : (
        <MainCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      {/* <Typography variant="subtitle2">Source</Typography> */}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3">Sources</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={downloadPNG}>Download PNG</MenuItem>
                    <MenuItem onClick={downloadCSV}>Download CSV</MenuItem>
                    {/* <MenuItem onClick={downloadSVG}>Download SVG</MenuItem> */}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                "& .apexcharts-menu.apexcharts-menu-open": {
                  bgcolor: "background.paper",
                  display: "none"
                },
              }}
            >
              <Chart
                options={chartData.options}
                series={chartData.series}
                type={chartData.type}
                height={chartData.height}
              />
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

TotalSourceChart.propTypes = {
  isLoading: PropTypes.bool,
};

export default TotalSourceChart;

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";

// material-ui
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

import Skeleton from "@mui/material/Skeleton";

// third-party
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

// project imports

import MainCard from "../cards/MainCard";

// assets
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
const chartData = {
  type: "area",
  height: 95,
  options: {
    chart: {
      id: "support-chart",
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "Ticket ",
        },
      },
      marker: {
        show: false,
      },
    },
  },
  series: [
    {
      data: [0, 15, 10, 50, 30, 40, 25],
    },
  ],
};

const SkeletonPopularCard = () => (
  <Card>
    <CardContent>
      <Grid container spacing="3">
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing="3"
          >
            <Grid item xs zeroMinWidth>
              <Skeleton variant="rectangular" height={20} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" height={20} width={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={150} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                spacing="3"
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" height={20} />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    spacing="3"
                    justifyContent="space-between"
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton variant="rectangular" height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant="rectangular" height={16} width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                spacing="3"
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" height={20} />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    spacing="3"
                    justifyContent="space-between"
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton variant="rectangular" height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant="rectangular" height={16} width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                spacing="3"
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" height={20} />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    spacing="3"
                    justifyContent="space-between"
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton variant="rectangular" height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant="rectangular" height={16} width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                spacing="3"
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" height={20} />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    spacing="3"
                    justifyContent="space-between"
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton variant="rectangular" height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant="rectangular" height={16} width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                spacing="3"
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" height={20} />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    spacing="3"
                    justifyContent="space-between"
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton variant="rectangular" height={20} />
                    </Grid>
                    <Grid item>
                      <Skeleton variant="rectangular" height={16} width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={20} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
    <CardContent
      sx={{ p: 1.25, display: "flex", pt: 0, justifyContent: "center" }}
    >
      <Skeleton variant="rectangular" height={25} width={75} />
    </CardContent>
  </Card>
);

const BajajAreaChartCard = () => {
  const theme = useTheme();
  const orangeDark = theme.palette.secondary[800];

  // const customization = useSelector((state) => state.customization);
  // const { navType } = customization;

  React.useEffect(() => {
    const newSupportChart = {
      ...chartData.options,
      colors: [orangeDark],
      tooltip: { theme: "light" },
    };
    ApexCharts.exec(`support-chart`, "updateOptions", newSupportChart);
  }, [orangeDark]);

  return (
    <Card sx={{ bgcolor: "secondary.light" }}>
      <Grid container sx={{ p: 2, pb: 0, color: "#fff" }}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle1" sx={{ color: "secondary.dark" }}>
                Source
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Chart {...chartData} />
    </Card>
  );
};

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const JobStatus = ({ isLoading, jobStatus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAll, setShowAll] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const sortedData = jobStatus?.sort((a, b) => {
    if (a.State === 'RUNNING' && b.State !== 'RUNNING') {
      return -1;
    } else if (a.State !== 'RUNNING' && b.State === 'RUNNING') {
      return 1;
    } else {
      return 0;
    }
  });
  

  const getStatusColor = (status) => {
    switch (status) {
      case "RUNNING":
        return "primary.dark";
      case "SCHEDULED":
        return "primary.dark";
      case "COMPLETED":
        return "success.dark";
      case "FAILED":
        return "orange.dark";
      case "CANCELLED":
        return "orange.dark";
      default:
        return "inherit";
    }
  };

  const formatTimeDifference = (timeDifferenceSeconds) => {
    const seconds = timeDifferenceSeconds % 60;
    const minutes = Math.floor((timeDifferenceSeconds / 60) % 60);
    const hours = Math.floor((timeDifferenceSeconds / 3600) % 24);
    const days = Math.floor((timeDifferenceSeconds / (3600 * 24)) % 7);
    const weeks = Math.floor(timeDifferenceSeconds / (3600 * 24 * 7));

    if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""}`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""}`;
    }
  };

  const calculateTimeDifference = (flowStartTime, endTime) => {
    const startTime = new Date(flowStartTime);
    const endTimeObj = endTime ? new Date(endTime) : new Date();

    const timeDifferenceMs = endTimeObj - startTime;
    const timeDifferenceSeconds = timeDifferenceMs / 1000;

    return timeDifferenceSeconds;
  };

  return (
    <>
      {isLoading ? (
        <SkeletonPopularCard />
      ) : (
        <MainCard content={false}>
          <CardContent>
            <Grid container spacing="3" sx={{ pt: "16px !important" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h4">Job Status</Typography>
                  </Grid>
                  {/* Add additional controls if needed */}
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <BajajAreaChartCard />
              </Grid> */}
              <Grid
                item
                xs={24}
                sx={{
                  maxHeight: showAll
                    ? { xs: "60vh", sm: "50vh", md: "30vh", lg: "63vh" }
                    : { xs: "60vh", sm: "50vh", md: "93vh", lg: "70vh" },
                  overflowY: showAll ? "visible" : "auto",
                  overflowX: "hidden",
                  height: showAll
                    ? { xs: "40vh", sm: "10vh", md: "80vh", lg: "70vh" }
                    : "",
                  pt: `${showAll ? "5% !important" : "3% !important"}`,
                }}
              >
                {sortedData?.length > 0 ? (
                  sortedData
                    .slice(0, showAll ? jobStatus.length : 3)
                    .map((job) => {
                      const timeDifferenceSeconds = calculateTimeDifference(
                        job.flow_start_time,
                        job.end_time
                      );
                      const formattedTime = formatTimeDifference(
                        timeDifferenceSeconds
                      );
                      return (
                        <React.Fragment key={job.job_id}>
                          <Grid container direction="column">
                            <Grid item>
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                  >
                                    {job.name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                  >
                                    {formattedTime}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: getStatusColor(job?.State) }}
                              >
                                {job?.State}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider sx={{ my: 1.2 }} />
                        </React.Fragment>
                      );
                    })
                ) : (
                  <div className="text-center mt-3 mb-5 text-secondary">
                    No Data Found
                  </div>
                )}
              </Grid>
            </Grid>
            <CardActions sx={{ p: 1.25, pt: 0, justifyContent: "center" }}>
              {jobStatus?.length > 0 ? (
                <Button
                  size="small"
                  disableElevation
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? "Show Less" : "See More"}
                  <ChevronRightOutlinedIcon />
                </Button>
              ) : null}
            </CardActions>
          </CardContent>
        </MainCard>
      )}
    </>
  );
};

JobStatus.propTypes = {
  isLoading: PropTypes.bool,
};

export default JobStatus;

import React, { useState, useRef, useEffect, useContext } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Copilot.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { Card, CardBody } from "reactstrap";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import Logo from "../../layouts/Logo";
import { DataContext } from "../../DataContext";
import {
  Box,
  InputAdornment,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ResLogo from "../../assets/images/logos/logo.png";
import DataTable from "react-data-table-component";
import TextField from "@mui/material/TextField";
import chatLogo from "../../assets/images/bg/bleubird-icon.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
const apiUrl = process.env.REACT_APP_COPILOT_API_URL;

const CoPilot = () => {
  const [chatData, setChatData] = useState({
    messages: [],
    userInput: "",
  });
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const { selectedChatId } = useContext(DataContext);
  const { containerAdd } = useContext(DataContext);
  const { newChat, setNewChat } = useContext(DataContext);
  const { container, setContainer } = useContext(DataContext);
  const [displayed, setDisplayed] = useState(false);
  const [typeDuration, setTypeDuration] = useState([]);
  const [containerLength, setContainerLength] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const typingRef = useRef(null);
  const [clickedValue, setClickedValue] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleInputChange = (e) => {
    setChatData({
      ...chatData,
      userInput: e.target.value,
    });
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const [search, setSearch] = useState("");

  const filteredData = clickedValue?.result?.filter((row) => {
    return Object?.values(row)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
    );
  });

  const columns = (data) => {
    if (!data || data.length === 0) return [];

    return Object.keys(data[0]).map((header) => ({
      name: header
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      selector: (row) => row[header],
      sortable: true,
    }));
  };

  const customStyles = {
    table: {
      style: {
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        fontSize: "14px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#FFF",
        borderBottom: "1px solid #E0E0E0",
        fontSize: "0.875rem",
        padding: "12px 0px",
        // position: 'sticky',
        // top: 0,
        // zIndex: 10,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        padding: "5px",
        minWidth: "150px !important",
        maxWidth: "150px !important",
        width: "fit-content !important",
      },
    },
    rows: {
      style: {
        minHeight: "48px",
        borderBottom: "0.5px solid #E0E0E0",
        "&:nth-of-type": {
          backgroundColor: "#F9F9F9",
        },
        "&:hover": {
          backgroundColor: "#F1F1F1",
        },
      },
    },
    cells: {
      style: {
        fontSize: "14px !important",
        padding: "10px !important",
        alignItems: "center",
        color: "#333",
        minWidth: "130px !important",
        maxWidth: "150px !important",
      },
    },
    pagination: {
      style: {
        fontSize: "14px",
        color: "#333",
        borderRadius: "0px 0px 6px 6px",
      },
    },
  };

  const renderTable = (data, title) => {
    if (!data || data.length === 0) return null;

    return (
      <div style={{ maxWidth: "100%", overflowX: "auto" }}>
        <div className="row d-flex col-12 mt-2 justify-content-end">
          {/* <div className="col-12 col-md-8"></div> */}
          <div className="col-12 col-md-4 d-flex justify-content-end">
            <TextField
              sx={{
                "& .MuiInputBase-input": {
                  padding: "9px !important",
                  borderRadius: "12px !important",
                },
              }}
              value={search}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={search}
              onChange={}
              style={{ marginBottom: "10px" }}
              InputProps={{
                style: {
                  height: "2rem",
                  padding: "0 10px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: !isFocused
                  ? {
                      top: "20%",
                      transform: "translateY(-50%)",
                      left: "20%",
                      transform: "translateX(-50%)",
                    }
                  : {},
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            /> */}
          </div>
        </div>
        <Box
          className="pt-3 pb-3 border mt-2 rounded"
          style={{ backgroundColor: "#fff", border: "rgb(207 209 212)" }}
        >
          <DataTable
            columns={columns(data)}
            data={filteredData}
            pagination
            paginationPerPage={15}
            paginationRowsPerPageOptions={[5, 10, 25, 50]}
            pointerOnHover
            fixedHeader={true}
            fixedHeaderScrollHeight="80vh"
            noDataComponent={
              <div style={{ padding: "24px", textAlign: "center" }}>
                <Typography variant="h6">No data found</Typography>
              </div>
            }
            customStyles={customStyles}
          />
        </Box>
      </div>
    );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const TypingEffect = ({
    content,
    summary,
    source,
    table,
    onTypingComplete,
  }) => {
    const [displayedText, setDisplayedText] = useState("");
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const typingSpeed = 10;

    let cont = ``;
    if (content?.props?.children?.props?.children && summary) {
      cont = `${content?.props?.children?.props?.children}<br /> ${summary}`;
    } else if (content?.props?.children?.props?.children) {
      cont = `${content?.props?.children?.props?.children}`;
    } else if (summary) {
      cont = `${summary}`;
    }

    useEffect(() => {
      if (!content) return;

      let index = 0;
      const intervalId = setInterval(() => {
        setDisplayedText((prev) => {
          const newText = prev + cont[index];
          index++;
          if (index >= cont.length) {
            clearInterval(intervalId);
            setIsTypingComplete(true);
            if (onTypingComplete) {
              onTypingComplete();
            }
          }
          return newText;
        });
      }, typingSpeed);

      return () => clearInterval(intervalId);
    }, [cont, typingSpeed, onTypingComplete]);

    useEffect(() => {
      if (isTypingComplete && typingRef.current) {
        typingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [isTypingComplete]);

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: displayedText }} />

        {isTypingComplete && (
          <>
            {((source && Array.isArray(source) && source.length > 0) ||
              (table && Array.isArray(table) && table.length > 0)) && (
              <p className="mb-0 mt-2 fw-bold">Sources:</p>
            )}

            <div className="d-flex">
              {source && Array.isArray(source) && source.length > 0 && (
                <div className="mt-2 me-3">
                  {source.map((source, index) => (
                    <div className="card-width mb-2" key={index}>
                      <div className="card-content p-2">
                        <i
                          className={"fas fa-link card-icon pt-2"}
                          style={{
                            marginBottom: "0.5% !important",
                            color: "#5CADEB",
                          }}
                        ></i>
                        <div>
                          <div className="card-title">
                            <a
                              href={source}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#5CADEB" }}
                            >
                              {source.split("/").pop()}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {table &&
                Array.isArray(table) &&
                table.length > 0 &&
                table.map((resultCategory, index) => {
                  const categoryEntries = Object.entries(resultCategory);
                  return categoryEntries.map(
                    ([categoryKey, categoryValue], entryIndex) => (
                      <div className="d-flex" key={entryIndex}>
                        <div className="mt-2">
                          <div className="card-width me-3 ps-3 pe-3 pt-1 pb-1 mb-2">
                            <div
                              className="card-content p-2"
                              onClick={() =>
                                handleKeyClick(categoryKey, categoryValue)
                              }
                            >
                              <i
                                className={"fas fa-database card-icon"}
                                style={{ color: "#5CADEB" }}
                              ></i>
                              <div
                                className={
                                  window.innerWidth < 768
                                    ? "card-title ps-3"
                                    : "card-title"
                                }
                                style={{ color: "#5CADEB" }}
                              >
                                {capitalizeFirstLetterOnly(
                                  categoryKey.replace(/_/g, " ")
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
          </>
        )}
      </div>
    );
  };

  const sendMessage = async (question) => {
    const query = question?.trim() || chatData?.userInput?.trim();

    if (query) {
      const newMessageId = chatData?.messages?.length;
      setLoading(true);
      scrollToBottom();
      const newMessages = [
        ...chatData.messages,
        { id: newMessageId, sender: "user", text: query },
      ];
      setChatData({
        messages: newMessages,
        userInput: "",
      });

      try {
        const response = await axios.post(`${apiUrl}/copilot/chat/`, {
          question: query,
          user_id: userData?.user_id,
          chat_container_id: selectedChatId,
        });

        setNewChat((prevValue) => prevValue + 1);
        if (selectedChatId == null) {
          setContainerLength(1);
        }

        let content = null;
        let item = response?.data;

        if (item?.answer && Array.isArray(item.answer)) {
          const firstAnswer = item.answer[0];
          let details = [];
          let documentContent = null;
          let docSource = null;

          if (firstAnswer.result && Array.isArray(firstAnswer.result)) {
            details = firstAnswer?.result;
          }

          if (firstAnswer.document) {
            documentContent = (
              <div className="mt-2 para-space">
                <div>{firstAnswer.document}</div>
              </div>
            );
          }
          if (firstAnswer.response) {
            documentContent = (
              <div className="mt-2">
                <div>{firstAnswer.response}</div>
              </div>
            );
          }

          if (firstAnswer.error) {
            documentContent = (
              <div className="mt-2">
                <div className="text-danger">{firstAnswer.error}</div>
              </div>
            );
          }

          if (firstAnswer.doc_source && Array.isArray(firstAnswer.doc_source)) {
            docSource = firstAnswer.doc_source;
          }

          content = (
            <>
              {(documentContent || firstAnswer.summary) && (
                <TypingEffect
                  content={documentContent ? documentContent : " "}
                  summary={firstAnswer.summary ? firstAnswer.summary : " "}
                  source={docSource}
                  table={details}
                  onTypingComplete={() => {
                    setDisplayed(true);
                  }}
                />
              )}
            </>
          );
        }

        setChatData((prevData) => ({
          ...prevData,
          messages: [
            ...prevData.messages,
            {
              id: newMessageId + 1,
              sender: "ai",
              text: content,
            },
          ],
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getChatContainerHistory = async () => {
    if (selectedChatId) {
      try {
        setLoading(true);
        setChatData({
          messages: [],
          userInput: "",
        });

        const response = await axios.get(
          `${apiUrl}/copilot/get-chat-container-history/`,
          {
            params: { chat_container_id: selectedChatId },
          }
        );

        const data = response?.data || [];
        const formattedMessages = data.map((item, index) => {
          let content = null;

          if (item?.content) {
            const contentItem = Array.isArray(item.content)
              ? item.content[0]
              : item.content;
            if (contentItem?.result && Array.isArray(contentItem.result)) {
              const resultItem = contentItem.result[0] || {};
              let details = [];
              let documentContent = null;
              let docSourceContent = null;

              if (resultItem) {
                details = Object.entries(resultItem).map(
                  ([key, value], itemIndex) => {
                    if (Array.isArray(value)) {
                      return (
                        <div className="mt-2" key={itemIndex}>
                          <div className="card-width me-3 ps-3 pe-3 pt-1 pb-1 mb-2">
                            <div
                              className="card-content p-2"
                              onClick={() => handleKeyClick(key, value)}
                            >
                              <i
                                className={"fas fa-database card-icon"}
                                style={{
                                  color: "#5CADEB",
                                }}
                              ></i>
                              <div
                                className={
                                  window.innerWidth < 768
                                    ? "card-title ps-3"
                                    : "card-title"
                                }
                                style={{ color: "#5CADEB" }}
                              >
                                {capitalizeFirstLetterOnly(
                                  key.replace(/_/g, " ")
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  }
                );
              }

              if (contentItem.document) {
                documentContent = (
                  <div className="mt-2 para-space">
                    <div>{contentItem.document}</div>
                  </div>
                );
              }

              if (
                contentItem.doc_source &&
                Array.isArray(contentItem.doc_source)
              ) {
                docSourceContent = (
                  <div className="mt-2">
                    {contentItem.doc_source.map((source, index) => (
                      <div
                        className={
                          window.innerWidth < 768
                            ? "card-width me-3 p-3 mb-2 w-50"
                            : "card-width me-3 mb-2"
                        }
                        key={index}
                      >
                        <div className="card-content p-2 w-100">
                          <i
                            className={"fas fa-link card-icon"}
                            style={{ color: "#5CADEB" }}
                          ></i>
                          <div
                            className={
                              window.innerWidth < 768
                                ? "card-title ps-3"
                                : "card-title"
                            }
                          >
                            <a
                              href={source}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#5CADEB" }}
                            >
                              {source.split("/").pop()}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }

              content = (
                <>
                  {documentContent}
                  {contentItem.summary && (
                    <div className="para-space">{contentItem.summary}</div>
                  )}
                  {resultItem != [] ||
                  (contentItem.doc_source &&
                    Array.isArray(contentItem.doc_source)) ? (
                    <p className="mb-0 mt-2 fw-bold">Sources:</p>
                  ) : null}
                  <div
                    className="d-flex mb-2"
                    style={
                      window.innerWidth < 768
                        ? { flexDirection: "column", alignItems: "flex-start" }
                        : {}
                    }
                  >
                    {docSourceContent}
                    <div className="d-flex">{details}</div>
                  </div>
                </>
              );
            } else if (contentItem?.doc_source || contentItem?.document) {
              let documentContent = null;
              let docSourceContent = null;
              if (contentItem.document) {
                documentContent = (
                  <div className="mt-2 para-space">
                    <div>{contentItem.document}</div>
                  </div>
                );
              }

              if (
                contentItem.doc_source &&
                Array.isArray(contentItem.doc_source)
              ) {
                docSourceContent = (
                  <div className="mt-2">
                    <p className="mb-0 mt-2 fw-bold">Sources:</p>
                    {contentItem.doc_source.map((source, index) => (
                      <div
                        className={
                          window.innerWidth < 768
                            ? "card-width me-3 mb-2 w-100"
                            : "card-width me-3 mb-2"
                        }
                        key={index}
                      >
                        <div className="card-content p-2">
                          <div
                            className={
                              window.innerWidth < 768
                                ? "card-title ps-3"
                                : "card-title"
                            }
                          >
                            <i
                              className={"fas fa-link card-icon"}
                              style={{ color: "#5CADEB" }}
                            ></i>
                            <a
                              href={source}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#5CADEB" }}
                            >
                              {source.split("/").pop()}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }

              content = (
                <>
                  {documentContent}
                  {contentItem.summary && (
                    <div className="para-space">{contentItem.summary}</div>
                  )}
                  {docSourceContent}
                </>
              );
            } else if (contentItem.response) {
              let documentContent = null;
              documentContent = (
                <div className="mt-2">
                  <div>{contentItem.response}</div>
                </div>
              );
              content = <>{documentContent}</>;
            } else if (typeof contentItem === "string") {
              content = (
                <div
                  dangerouslySetInnerHTML={{
                    __html: contentItem
                      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                      .replace(/\n/g, "<br />"),
                  }}
                />
              );
            }
          }

          return {
            id: index,
            sender: item.type === "human" ? "user" : "ai",
            text: content,
          };
        });

        setChatData({
          messages: formattedMessages,
          userInput: "",
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setChatData({
        messages: [],
        userInput: "",
      });
    }
  };

  const capitalizeFirstLetterOnly = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleKeyClick = (key, value) => {
    setClickedValue({ name: key, result: value });
    setIsOpen(true);
  };

  useEffect(() => {
    if (containerLength != 1 || selectedChatId == null) {
      getChatContainerHistory();
    }
    if (containerLength != 1 || selectedChatId == null) {
      getChatContainerHistory();
    }
  }, [selectedChatId, containerAdd]);

  useEffect(() => {
    if (container == 0) {
      getChatContainerHistory();
      setContainer(1);
    }
  }, [container]);

  const handleCardClick = (question) => {
    sendMessage(question);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData.messages]);

  const CardComponent = ({ iconClass, color, text, handleClick }) => (
    <div className="col-6 col-md-2 mb-3 mt-3 d-flex justify-content-center">
      <Card
        className="shadow-sm border-0"
        style={
          window.innerWidth < 768
            ? {
                width: "40vw",
                cursor: "pointer",
                borderRadius: "12px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                padding: "5%",
                overflow: "hidden",
              }
            : {
                width: "220px !important",
                cursor: "pointer",
                borderRadius: "12px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                overflow: "hidden",
                paddingTop: "15%",
              }
        }
        onClick={handleClick}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.05)";
          e.currentTarget.style.boxShadow = "0 8px 16px rgba(0,0,0,0.15)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
          e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
        }}
      >
        <CardBody
          className="p-4 d-flex flex-column align-items-center justify-content-center"
          style={{ height: "150px" }}
        >
          <i
            className={`${iconClass} mb-2`}
            style={{ color: color, fontSize: "26px" }}
          ></i>
          <p
            className="text-secondary"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "center",
              color: "#333",
              fontSize: window.innerWidth < 768 ? "12px" : "14px",
            }}
          >
            {text}
          </p>
        </CardBody>
      </Card>
    </div>
  );

  return (
    <div className="chat-container col-sm-12">
      {chatData?.messages?.length > 0 ? (
        <div className="chat-box" ref={chatContainerRef}>
          {chatData.messages.map((msg, index) => (
            <div
              key={index}
              className="row d-flex"
              style={
                window.innerWidth < 768
                  ? { flexDirection: "column", alignItems: "flex-start" }
                  : {}
              }
            >
              <div
                className={msg.sender === "user" ? "d-none" : "col-1 pt-1 me-4"}
              >
                <img src={ResLogo} width="50" height="45"></img>
              </div>
              <div
                className={`message ${msg.sender} col-11`}
                style={msg.sender === "user" ? {} : { marginLeft: "-2.7rem" }}
              >
                {msg.text}
              </div>
            </div>
          ))}
          {loading ? (
            <div className="d-flex">
              <div className={`col-1 ${isMobile ? "" : "ms-3"} `}>
                <img src={chatLogo} width="60" height="45"></img>
              </div>
              <div className={`pt-3 ${isMobile ? "ms-5" : ""}`}>
                <div className="dot-loader">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center mt-md-5 mt-sm-0 text-center">
            <div className="d-block d-md-none">
              <div className="me-2">
                <Logo />
              </div>
            </div>
            <div className="d-none d-md-flex me-2">
              <Logo />
            </div>
            <h6 className="fw-bold text-secondary pt-3">
              Streamline tasks and boost efficiency with intelligent chat that
              simplifies your workflow effortlessly.
            </h6>
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center mb-5 cardCom">
            <div className="row w-100 d-flex justify-content-center">
              <CardComponent
                iconClass="fa-solid fa-dollar-sign"
                color="skyblue"
                text="Show me the fraud transactions identified in the past week"
                handleClick={() =>
                  handleCardClick(
                    "Show me the fraud transactions identified in the past week"
                  )
                }
              />
              <CardComponent
                iconClass="fa-solid fa-building-columns"
                color="orange"
                text="How many fraud transactions occurred today?"
                handleClick={() =>
                  handleCardClick("How many fraud transactions occurred today?")
                }
              />
              <CardComponent
                iconClass="fas fa-exclamation-triangle"
                color="red"
                text="What should be done immediately after a fraud transaction is detected?"
                handleClick={() =>
                  handleCardClick(
                    "What should be done immediately after a fraud transaction is detected?"
                  )
                }
              />
            </div>
          </div>
        </>
      )}

      <div className="parent-container">
        <div className="input-container inputArea p-2 ps-3  d-flex mt-2">
          {loading ? (
            <input
              type="text"
              value="Generating response..."
              disabled
              className="search-input bg-white"
              style={{ border: "none", outline: "none" }}
            />
          ) : (
            <input
              type="text"
              value={chatData?.userInput}
              onChange={handleInputChange}
              placeholder="Ask Anything..."
              onKeyDown={handleKeyDown}
              className="search-input"
              style={{ border: "none", outline: "none" }}
            />
          )}
          <button
            className="search-button me-3"
            onClick={() => sendMessage()}
            disabled={loading}
          >
            <SendIcon />
          </button>
        </div>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          style: {
            width: window.innerWidth < 768 ? "100%" : "45%",
            background: "#fff",
          },
        }}
        transitionDuration={1000}
      >
        <div role="presentation">
          <div
            className="p-3"
            style={{
              backgroundColor: "#fff",
              color: "black",
              height: "40px",
            }}
          >
            <div className="row align-items-center">
              <div className="col-9">
                <h6 className="text-uppercase fw-bold mb-0">
                  {clickedValue?.name.replace(/_/g, " ")}
                </h6>
              </div>
              <div className="col-3 text-end pe-4">
                <p
                  className="mb-0 fw-bold"
                  style={{ cursor: "pointer", color: "#67B2EC" }}
                  onClick={toggleDrawer(false)}
                >
                  {`< back`}
                </p>
              </div>
            </div>
            <hr className="text-secondary" />
          </div>

          <div className="mt-3" style={{ maxWidth: "100%", overflowX: "auto" }}>
            {clickedValue &&
            clickedValue.result &&
            clickedValue.result.length > 0 ? (
              <div className="table-responsive mt-3">
                {renderTable(clickedValue?.result, clickedValue?.name)}
              </div>
            ) : (
              <div className="alert alert-warning mt-3">No data available</div>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default CoPilot;
